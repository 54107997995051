import { Device } from '../';
import { Grid, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';



const DevicesList = ( { devs } ) => (
	<Fragment>
	{ devs.length === 0 &&
		<div style={ { margin: '10vH 0' } }>
			<Typography variant="h6" align="center">keine Geräte hinzugefügt</Typography>
		</div>
	}
	{ devs.length !== 0 &&
		<div style={ { margin: '24px 0' } }>
			<Grid container spacing={ 1 }>
				{ devs.map( dev => <Device dev={ dev } key={ `dev-${ dev.id }` } /> ) }
			</Grid>
		</div>
	}
	</Fragment>
);



export default connect( state => ( {
	devs: state.inv.devs
} ) )( DevicesList );