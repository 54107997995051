import { createHash } from 'crypto';
import { loginState } from './utils';



const msgs = [];



const defaultState = () => ( {
	gui: {
		devDiscAct:		false,	// device discovering active
		discDevs:		[],		// discovered devices
		discTerms:		[],		// dicsovered terminals
		menuOpen:		false,	// state of main menu
		prgrs:			0,		// progress
		termDiscAct:	false	// terminal discovering active
	},
	inv: {
		devs:			[],
		terms:			[]
	},
	log: {
		itms:			[],
		flgs:			14
	},
	mea: {
		meas:			[],
		measStart:		null,
		measStop:		null,
		measMin:		null,
		measMax:		null,
		measAvg:		null
	},
	plc: {
		cfg: {
			act:		false,
			cycle:		10,
		},
		mems:			[]
	},
	sched: {
		cfg: {
			act:		false,
			lat:		0,
			lng:		0
		},
		itms:			[]
	},
	sys: {
		login:			loginState.NO,
		cnnct:			false,
		ver:			'---',
		psh: {
			act: 		false,
			usr: 		'',
			tkn: 		''
		},
	}
} );



export const reducer = ( state = defaultState(), action ) => {
	let msg;

	switch( action.type ) {

		case 'GUI_MENU_OPEN':
			return {
				...state,
				gui: {
					...state.gui,
					menuOpen: action.data.menuOpen
				}
			};

		case 'WS_CONNECT':
			return {
				...state,
				sys: {
					...state.sys,
					cnnct: true
				}
			};

		case 'WS_DISCONNECT':
			return defaultState();

		case 'WS_RECEIVE':
			msg = action.data;

			if( !!msgs[ msg.uuid ] ) {
				switch( msg.typ ) {

					case 1: // MsgTypRequest
						switch( msg.cmd ) {

							case 3: // MsgCmdTermDisc
								return {
									...state,
									gui: {
										...state.gui,
										discTerms: [],
										termsDiscAct: true
									}
								};

							case 4: // MsgCmdDevDisc
								return {
									...state,
									gui: {
										...state.gui,
										devsDiscAct: true,
										discDevs: []
									}
								};

							case 11: // MsgCmdMeaHisGet
								return {
									...state,
									gui: {
										...state.gui,
										prgrs: 0
									},
									mea: {
										...state.mea,
										meas: [],
										measStart: msg.data.tsp,
										measStop: msg.data.tsp + msg.data.dur,
										measMin: null,
										measMax: null,
										measAvg: null
									}
								};

							case 18: // MsgCmdSysSalt
								return {
									...state,
									sys: {
										...state.sys,
										login: loginState.PRGRS
									}
								};

							case 31: // MsgCmdLogItmGet
								return {
									...state,
									gui: {
										...state.gui,
										prgrs: 0
									},
									log: {
										...state.log,
										itms: [],
										flgs: msg.data.flgs
									}
								};

						}

						break;

					case 2: // MsgTypResponse
						switch( msg.cmd ) {

							case 3: // MsgCmdTermDisc
								delete msgs[ msg.uuid ];

								return {
									...state,
									gui: {
										...state.gui,
										termsDiscAct: false
									}
								};

							case 4: // MsgCmdDevDisc
								delete msgs[ msg.uuid ];

								return {
									...state,
									gui: {
										...state.gui,
										devsDiscAct: false
									}
								};

							case 11: // MsgCmdMeaHisGet
								if( msg.err === 0 ) {
									const meas = [ ...state.mea.meas, {
										id:		msg.data.id,
										termId: msg.data.termId,
										tsp:	msg.data.tsp,
										val:	msg.data.val
									} ];
				
									if( msg.prgrs < 100 ) {
										return {
											...state,
											gui: {
												...state.gui,
												prgrs: msg.prgrs
											},
											mea: {
												...state.mea,
												meas
											}
										};
									} else {
										delete msgs[ msg.uuid ];
				
										return {
											...state,
											gui: {
												...state.gui,
												prgrs: 100
											},
											mea: ( () => {
												let min, max, sum = 0;

												meas.forEach( ( mea, idx ) => {
													min = idx === 0 || min > mea.val ? mea.val : min;
													max = idx === 0 || max < mea.val ? mea.val : max;
													sum += mea.val;
												} );

												return {
													...state.mea,
													meas,
													measMin: min,
													measMax: max,
													measAvg: sum / meas.length
												};
											} )()
										};
									}
								} else {
									delete msgs[ msg.uuid ];

									return {
										...state,
										gui: {
											...state.gui,
											prgrs: 100
										}
									};
								}

							case 19: // MsgCmdSysLogin
								delete msgs[ msg.uuid ];

								return {
									...state,
									sys: {
										...state.sys,
										login: !( msg.flgs & 0x1 ) ? loginState.OK : loginState.ERR
									}
								};

							case 20: // MsgCmdSysLogout
								delete msgs[ msg.uuid ];

								return {
									...state,
									sys: {
										...state.sys,
										login: loginState.NO
									}
								};

							case 31: // MsgCmdLogItmGet
								if( msg.err === 0 ) {
									if( msg.prgrs === 100 ) {
										delete msgs[ msg.uuid ];
									}

									return {
										...state,
										gui: {
											...state.gui,
											prgrs: msg.prgrs
										},
										log: {
											...state.log,
											itms: [ ...state.log.itms, {
												id:		msg.data.id,
												tsp:	msg.data.tsp,
												name:	msg.data.name,
												txt:	msg.data.txt,
												flgs:	msg.data.flgs
											} ]
										}
									};
								} else {
									delete msgs[ msg.uuid ];

									return {
										...state,
										gui: {
											...state.gui,
											prgrs: 100
										}
									};
								}

							default:
								delete msgs[ msg.uuid ];

						}

						break;

					case 3: // MsgTypNotification
						switch( msg.cmd ) {

							case 3: // MsgCmdTermDisc
								return {
									...state,
									gui: {
										...state.gui,
										discTerms: [ ...state.gui.discTerms, {
											devId:	msg.data.devId,
											grp:	msg.data.grp,
											num:	msg.data.num,
											obis:	msg.data.obis,
											name:	msg.data.name,
											unit:	msg.data.unit,
					
											_hash:	createHash( 'sha256' ).update( `${ msg.data.devId }|${ msg.data.grp }|${ msg.data.num }|${ msg.data.obis }` ).digest( 'hex' ),
											_disc:	true
										} ]
									}
								};

							case 4: // MsgCmdDevDisc
								return {
									...state,
									gui: {
										...state.gui,
										discDevs: sortDevs( [ ...state.gui.discDevs, {
											id:		0,
											grp:	msg.data.grp,
											mac:	msg.data.mac,
											name:	msg.data.name,
											uri:	msg.data.uri,
											ver:	msg.data.ver
										} ] )
									}
								};

						}
					
						break;

				}
			}

			switch( true ) {

				case msg.cmd === 1 && msg.typ === 2 && msg.err === 0: // MsgCmdDevCfgAdd && MsgTypResponse
				case msg.cmd === 5 && msg.typ === 2 && msg.err === 0: // MsgCmdDevCfgGet && MsgTypResponse
				case msg.cmd === 6 && msg.typ === 2 && msg.err === 0: // MsgCmdDevCfgSet && MsgTypResponse
					for( let idx in state.inv.devs ) {
						if( state.inv.devs[ idx ].id === msg.data.id ) {
							state.inv.devs[ idx ] = {
								...state.inv.devs[ idx ],
								name:	msg.data.name,
								uri:	msg.data.uri,
								ver:	msg.data.ver,
								pw:		msg.data.pw,
								flgs:	( msg.data.flgs & 0xff00 ) | ( state.inv.devs[ idx ].flgs & 0x00ff )
							};

							return { ...state, inv: { ...state.inv, devs: sortDevs( state.inv.devs ) } };
						}
					}

					return {
						...state,
						inv: {
							...state.inv,
							devs: sortDevs( [ ...state.inv.devs, {
								id:		msg.data.id,
								grp:	msg.data.grp,
								name:	msg.data.name,
								mac:	msg.data.mac,
								uri:	msg.data.uri,
								ver:	msg.data.ver,
								pw:		msg.data.pw,
								flgs:	msg.data.flgs & 0xff00
							} ] )
						}
					};

				case msg.cmd === 2 && msg.typ === 2 && msg.err === 0: // MsgCmdDevCfgDel && MsgTypResponse
					return {
						...state,
						inv: {
							...state.inv,
							devs: state.inv.devs.filter( dev => dev.id !== msg.data.id )
						}
					};

				case msg.cmd === 17 && msg.typ === 3: // MsgCmdDevStateGet && MsgTypNotification
				case msg.cmd === 17 && msg.typ === 2 && msg.err === 0: // MsgCmdDevStateGet && MsgTypResponse
					for( let idx in state.inv.devs ) {
						if( state.inv.devs[ idx ].id === msg.data.id ) {
							state.inv.devs[ idx ] = {
								...state.inv.devs[ idx ],
								flgs: ( state.inv.devs[ idx ].flgs & 0xff00 ) | ( msg.data.flgs & 0x00ff )
							};

							return { ...state, inv: { ...state.inv, devs: [ ...state.inv.devs ] } };
						}
					}

					return state;

				case msg.cmd === 13 && msg.typ === 2 && msg.err === 0: // MsgCmdMeaHisAdd && MsgTypResponse
					if( state.mea.meas.length !== 0 && state.mea.meas[ 0 ].termId == msg.data.termId && state.mea.measStart <= msg.data.tsp && state.mea.measStop >= msg.data.tsp + msg.data.dur ) {
						return { ...state, mea: { ...state.mea, meas: [ ...state.mea.meas, {
							id:		msg.data.id,
							termId:	msg.data.termId,
							tsp:	msg.data.tsp,
							val:	msg.data.val
						} ] } };
					} else {
						return state;
					}

				case msg.cmd === 33 && msg.typ === 2 && msg.err === 0: // MsgCmdPlcCfgGet
				case msg.cmd === 34 && msg.typ === 2 && msg.err === 0: // MsgCmdPlcCfgSet
					return { ...state, plc: { ...state.plc, cfg: { act: msg.json.act, cycle: msg.json.cycle } } };

				case msg.cmd === 29 && msg.typ === 2 && msg.err === 0: // MsgCmdSchedCfgGet
				case msg.cmd === 30 && msg.typ === 2 && msg.err === 0: // MsgCmdSchedCfgSet
					return { ...state, sched: { ...state.sched, cfg: { act: msg.data.act, lat: msg.data.lat, lng: msg.data.lng } } };

				case msg.cmd === 24 && msg.typ === 2 && msg.err === 0: // MsgCmdSchedItmAdd && MsgTypResponse
				case msg.cmd === 22 && msg.typ === 3 && msg.err === 0: // MsgCmdSchedItmGet && MsgTypNotification
				case msg.cmd === 22 && msg.typ === 2 && msg.err === 0: // MsgCmdSchedItmGet && MsgTypResponse
				case msg.cmd === 23 && msg.typ === 2 && msg.err === 0: // MsgCmdSchedItmSet && MsgTypResponse
					for( let idx in state.sched.itms ) {
						if( state.sched.itms[ idx ].id === msg.data.id ) {
							state.sched.itms[ idx ] = {
								...state.sched.itms[ idx ],
								termId:	msg.data.termId,
								tsp:	msg.data.tsp,
								dly:	msg.data.dly,
								val:	msg.data.val,
								flgs:	msg.data.flgs
							};

							return {
								...state,
								sched: {
									...state.sched,
									itms: sortSchedItms( state.sched.itms )
								}
							};
						}
					}

					return {
						...state,
						sched: {
							...state.sched,
							itms: sortSchedItms( [ ...state.sched.itms, {
								id:		msg.data.id,
								termId:	msg.data.termId,
								tsp:	msg.data.tsp,
								dly:	msg.data.dly,
								val:	msg.data.val,
								flgs:	msg.data.flgs
							} ] )
						}
					};

				case msg.cmd === 25 && msg.typ === 2 && msg.err === 0: // MsgCmdSchedItmDel
					return { ...state, sched: { ...state.sched, itms: state.sched.itms.filter( itm => itm.id !== msg.data.id ) } };

				case msg.cmd === 26 && msg.typ === 2 && msg.err === 0: // MsgCmdLogPshGet
					return { ...state, sys: { ...state.sys, psh: { act: msg.data.act, usr: msg.data.name, tkn: msg.data.pw } } };

				case msg.cmd === 14 && msg.typ === 3: // MsgCmdTermBattGet && MsgTypNotification
				case msg.cmd === 14 && msg.typ === 2 && msg.err === 0: // MsgCmdTermBattGet && MsgTypResponse
					for( let idx in state.inv.terms ) {
						if( state.inv.terms[ idx ].id === msg.data.id ) {
							state.inv.terms[ idx ] = {
								...state.inv.terms[ idx ],
								batt: parseInt( msg.data.val )
							};

							return { ...state, inv: { ...state.inv, terms: [ ...state.inv.terms ] } };
						}
					}

					return state;

				case msg.cmd === 7 && msg.typ === 2 && msg.err === 0: // MsgCmdTermCfgAdd && MsgTypResponse
				case msg.cmd === 9 && msg.typ === 2 && msg.err === 0: // MsgCmdTermCfgGet && MsgTypResponse
				case msg.cmd === 10 && msg.typ === 2 && msg.err === 0: // MsgCmdTermCfgSet && MsgTypResponse
					for( let idx in state.inv.terms ) {
						if( state.inv.terms[ idx ].id === msg.data.id ) {
							state.inv.terms[ idx ] = {
								...state.inv.terms[ idx ],
								cat:		msg.data.cat,
								name:		msg.data.name,
								unit:		msg.data.unit,
								valIni:		msg.data.valIni,
								lwrLmt:		msg.data.lwrLmt,
								upprLmt:	msg.data.upprLmt,
								battLmt:	msg.data.battLmt,
								meaSve:		msg.data.meaSve,
								flgs:		( msg.data.flgs & 0xff00 ) | ( state.inv.terms[ idx ].flgs & 0x00ff )
							};

							return { ...state, inv: { ...state.inv, terms: sortTerms( state.inv.terms ) } };
						}
					}

					return { ...state, inv: { ...state.inv, terms: sortTerms( [ ...state.inv.terms, {
						id:			msg.data.id,
						devId:		msg.data.devId,
						grp:		msg.data.grp,
						num:		msg.data.num,
						obis:		msg.data.obis,
						cat:		msg.data.cat,
						name:		msg.data.name,
						unit:		msg.data.unit,
						valIni:		msg.data.valIni,
						lwrLmt:		msg.data.lwrLmt,
						upprLmt:	msg.data.upprLmt,
						battLmt:	msg.data.battLmt,
						meaSve:		msg.data.meaSve,

						valTsp:		0,
						val:		0,
						batt:		0,

						flgs:		msg.data.flgs & 0xff00,

						_hash:		createHash( 'sha256' ).update( `${ msg.data.devId }|${ msg.data.grp }|${ msg.data.num }|${ msg.data.obis }` ).digest( 'hex' ),
						_disc:		false
					} ] ) } };

				case msg.cmd === 8 && msg.typ === 2 && msg.err === 0: // MsgCmdTermCfgDel && MsgTypResponse
					return { ...state, inv: { ...state.inv, terms: state.inv.terms.filter( term => term.id !== msg.data.id ) } };

				case msg.cmd === 16 && msg.typ === 3: // MsgCmdTermMeaGet && MsgTypNotification
				case msg.cmd === 16 && msg.typ === 2 && msg.err === 0: // MsgCmdTermMeaGet && MsgTypResponse
				case msg.cmd === 15 && msg.typ === 2 && msg.err === 0: // MsgCmdTermMeaSet && MsgTypResponse
					for( let idx in state.inv.terms ) {
						if( state.inv.terms[ idx ].id === msg.data.id ) {
							state.inv.terms[ idx ] = {
								...state.inv.terms[ idx ],
								valTsp:	msg.data.tsp,
								val:	msg.data.val,
								flgs:	( state.inv.terms[ idx ].flgs & 0xff00 ) | ( msg.data.flgs & 0x00ff )
							};

							return { ...state, inv: { ...state.inv, terms: [ ...state.inv.terms ] } };
						}
					}

					return state;

				default:
					return state;

			}

		case 'WS_SEND':
			msg = action.data;

			if( msg.typ === 1 ) {
				msgs[ msg.uuid ] = msg;
			}

			return state;

		default:
			return state;

	}
};



const sortDevs = devs => [ ...devs.sort( ( devA, devB ) => {
	if( devA.name.toLowerCase() < devB.name.toLowerCase() ) {
		return -1;
	} else if( devA.name.toLowerCase() > devB.name.toLowerCase() ) {
		return 1;
	} else if( devA.mac.toLowerCase() < devB.mac.toLowerCase() ) {
		return  -1;
	} else if( devA.mac.toLowerCase() < devB.mac.toLowerCase() ) {
		return 1;
	} else {
		return devA.id < devB.id ? -1 : 1;
	}
} ) ];

const sortTerms = terms => [ ...terms.sort( ( termA, termB ) => {
	if( termA.name.toLowerCase() < termB.name.toLowerCase() ) {
		return -1;
	} else if( termA.name.toLowerCase() > termB.name.toLowerCase() ) {
		return 1;
	} else if( termA.grp < termB.grp ) {
		return -1;
	} else if( termA.grp > termB.grp ) {
		return 1;
	} else if( termA.num < termB.num ) {
		return -1;
	} else if( termA.num > termB.num ) {
		return 1;
	} else {
		return termA.id < termB.id ? -1 : 1;
	}
} ) ];

const sortSchedItms = itms => {
	return [ ...itms.sort( ( itmA, itmB ) => itmA.id < itmB.id ? -1 : 1 ) ];
};