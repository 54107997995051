import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	withMobileDialog
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import moment, { unix } from 'moment';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { getFormattedDateTime } from '../../utils';
import { v4 as uuidv4 } from 'uuid';



const TerminalStatus = ( {
	measAvg, measMax, measMin, measStart, measStop, terms, prgrs,
	getMeas, setVal,
	fullScreen, history
} ) => {
	const loc = useLocation();
	const params = useParams();

	const term = ( () => {
		if( !!loc.pathname.match( /\/terms\/\d+\/status$/ ) ) {
			for( let term of terms ) {
				if( term.id === +params.termId ) {
					return term;
				}
			}
		}
	} )();

	if( !!term ) {
		useEffect( () => {
			const date = moment().startOf( 'd' );
			getMeas( term.id, date.unix(), date.clone().add( 1, 'd' ).diff( date, 's' ) );
		}, [] );

		return(
			<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
				<DialogTitle>{ term.name }</DialogTitle>
				<DialogContent>
					{ ( () => {
						switch( true ) {

							case ( term.flgs & 0x2000 ) === 0:
								return <Typography variant="h6" color="primary" align="center">inaktiv</Typography>;

							case ( term.flgs & 0x1 ) !== 0:
								return <Typography variant="h6" color="primary" align="center">kein Messwert</Typography>;

							case term.valTsp === 0:
								return <Typography variant="h6" color="primary" align="center">warte auf Messwert</Typography>;

							case term.cat === 1: // TermCatDoor
							case term.cat === 2: // TermCatWindow
								return <Typography variant="h6" color="primary" align="center">{ term.val === 0 ? 'geschlossen' : 'geöffnet' }</Typography>;

							case term.grp === 2: // TermGrpWebIoDigIn
							case term.grp === 5: // TermGrpWebIoDigRo
								return <Typography variant="h6" color="primary" align="center">{ term.val === 0 ? 'ausgeschaltet' : 'eingeschaltet' }</Typography>;

							case term.grp === 3: // TermGrpWebIoDigOut
							return(
								<ButtonGroup fullWidth size="small" style={ { margin: '8px 0 0' } }>
									<Button variant={ !!term.val ? 'outlined' : 'contained' } color={ !!term.val ? 'default' : 'primary' } onClick={ () => setVal( term.id, 0 ) }>{ !!term.val ? 'ausschalten' : 'ausgeschaltet' }</Button>
									<Button variant={ !!term.val ? 'contained' : 'outlined' } color={ !!term.val ? 'primary' : 'default' } onClick={ () => setVal( term.id, 1 ) }>{ !!term.val ? 'eingeschaltet' : 'einschalten' }</Button>
								</ButtonGroup>
							);

							case term.grp === 1: // TermGrpAnIn
							case term.grp === 4: // TermGrpCount
							case term.grp === 6: // TermGrpAnOut
								return <Typography variant="h6" color="primary" align="center">{ [ term.val.toFixed( 1 ).replace( '.', ',' ), term.unit ] }</Typography>;

							default:
								return <Typography variant="h6" color="primary" align="center">{ [ term.val, ' (', term.grp, ')' ] }</Typography>;

						}
					} )() }
				{ prgrs !== 100 &&
					<div style={ { height: '250px', margin: '24px 0 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }>
						<Typography variant="h6">Messwerte werden geladen</Typography>
						<Typography variant="subtitle1">{ prgrs }%</Typography>
					</div>
				}
				{ ( term.grp === 1 || term.grp === 4 || term.grp === 6 ) &&
					<Fragment>
					{ prgrs === 100 &&
						<TerminalStatusChart />
					}
						<TableContainer style={ { margin: '24px 0 0' } }>
							<Table size="small">
								<TableBody>
									<TableRow>
										<TableCell variant="head" align="right" width="50%" style={ { padding: '6px 16px' } }>Minimum</TableCell>
										<TableCell>{ measMin === null ? '---' : measMin.toFixed( 1 ).replace( '.', ',') + term.unit }</TableCell>
									</TableRow>
									<TableRow>
										<TableCell variant="head" align="right" width="50%" style={ { padding: '6px 16px' } }>Maximum</TableCell>
										<TableCell>{ measMax === null ? '---' : measMax.toFixed( 1 ).replace( '.', ',') + term.unit }</TableCell>
									</TableRow>
									<TableRow>
										<TableCell variant="head" align="right" width="50%" style={ { padding: '6px 16px' } }>Differenz</TableCell>
										<TableCell>{ measMax === null || measMin === null ? '---' : ( measMax - measMin ).toFixed( 1 ).replace( '.', ',' ) + term.unit }</TableCell>
									</TableRow>
									<TableRow>
										<TableCell variant="head" align="right" width="50%" style={ { border: 'none', padding: '6px 16px' } }>Durchschnitt</TableCell>
										<TableCell style={ { border: 'none' } }>{ measAvg === null ? '---' : measAvg.toFixed( 1 ).replace( '.', ',') + term.unit }</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Fragment>
				}
				{ ( term.grp === 2 || term.grp === 3 || term.grp === 5 ) && prgrs === 100 &&
					<TerminalStatusDigital term={ term }/>
				}
				<div style={ { textAlign: 'center' } }>
					<IconButton children={ <NavigateBefore /> } color="primary" onClick={ () => {
						const date = unix( measStart ).subtract( 1, measStop - measStart <= 90000 ? 'd' : 'w' );
						getMeas( term.id, date.unix(), measStart - date.unix() );
					} } />
					<ButtonGroup color="primary" size="small" style={ { margin: '24px 48px' } }>
						<Button variant={ measStop - measStart <= 90000 ? 'contained' : 'outlined' } onClick={ () => {
							const date = moment().startOf( 'd' );
							getMeas( term.id, date.unix(), date.clone().add( 1, 'd' ).diff( date, 's' ) );
						} } style={ { width: '70px' } }>Tag</Button>
						<Button variant={ measStop - measStart > 90000 ? 'contained' : 'outlined' } onClick={ () => {
							const date = moment().startOf( 'W' );
							getMeas( term.id, date.unix(), date.clone().add( 1, 'w' ).diff( date, 's' ) );
						} } style={ { width: '70px' } }>Woche</Button>
					</ButtonGroup>
					<IconButton children={ <NavigateNext /> } color="primary" onClick={ () => {
						const date = unix( measStop ).add( 1, measStop - measStart <= 90000 ? 'd' : 'w' );
						getMeas( term.id, measStop, date.unix() - measStop );
					} } />
				</div>
				{ !!term.batt &&
					<div style={ { margin: '24px 0 0' } }>
						<DialogContentText>Batterie</DialogContentText>
						<div style={ { margin: '0 16px' } }>
							<Typography align="center" variant="body1">{ `${ term.batt }%` }</Typography>
							<LinearProgress variant="determinate" value={ term.batt } />
						</div>
					</div>
				}
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={ history.goBack }>schliessen</Button>
				</DialogActions>
			</Dialog>
		);
	} else {
		history.goBack();
		return null;
	}
};

const TerminalStatusChart = connect( state => ( {
	meas:		state.mea.meas,
	measStart:	state.mea.measStart,
	measStop:	state.mea.measStop
} ) )( ( { meas, measStart, measStop } ) => (
	<div style={ { height: '250px', margin: '24px 0 0' } }>
		<Typography variant="subtitle2" align="center">{ unix( measStart ).format( 'DD.MM.YYYY' ) + ( ( measStop - measStart ) > 90000 ? ' - ' + unix( measStop - 1 ).format( 'DD.MM.YYYY' ) : '' ) }</Typography>
		<ResponsiveContainer>
			<LineChart data={ ( () => {
				const tmpMeas = [];

				if( meas.length === 0 ) {
					for( let tsp = measStart; tsp <= measStop; tsp += 60 ) {
						tmpMeas.push( { tsp, val: tsp === measStart ? 0 : tsp === measStop ? 100 : null } );
					}
				} else {
					for( let tsp = measStart; tsp < meas[ 0 ].tsp; tsp += 60 ) {
						tmpMeas.push( { tsp, val: null } );
					}

					for( const idx in meas ) {
						if( idx > 0 ) {
							for( let tsp = meas[ idx - 1 ].tsp + 60; tsp < meas[ idx ].tsp; tsp += 60 ) {
								tmpMeas.push( { tsp, val: null } );
							}
						}

						tmpMeas.push( meas[ idx ] );
					}

					for( let tsp = meas[ meas.length - 1 ].tsp + 60; tsp <= measStop; tsp += 60 ) {
						tmpMeas.push( { tsp, val: null } );
					}
				}

				return tmpMeas;
			} )() }>
				<Line dataKey="val" stroke="#0080c8" dot={ false } isAnimationActive={ false } />
				<XAxis dataKey="tsp" ticks={ ( () => {
					const ticks = [ measStart ];

					for( let date = unix( measStart ); date.unix() < measStop; ) {
						ticks.push( ( measStop - measStart <= 90000 ? date.add( 6, 'h' ) : date.add( 1, 'd' ) ).unix() );
					}

					return ticks;
				} )() } tickFormatter={ tsp =>
					measStop - measStart <= 90000 ? unix( tsp ).format( 'HH:mm' ) : [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ][ unix( tsp ).day() ]
				} interval="preserveStartEnd" />
				<YAxis domain={ [ 'auto', 'auto' ] }/>
			</LineChart>
		</ResponsiveContainer>
	</div>
) );

const TerminalStatusDigital = connect( state => ( {
	meas:		state.mea.meas,
	measStart:	state.mea.measStart,
	measStop:	state.mea.measStop
} ) )( ( { meas, measStart, measStop, term } ) => {
	const theme = useTheme();

	return(
		<div style={ { display: 'flex', flexDirection: 'column', height: '250px', margin: '24px 0 0' } }>
			<Typography variant="subtitle2" align="center">{ unix( measStart ).format( 'DD.MM.YYYY' ) + ( ( measStop - measStart ) > 90000 ? ' - ' + unix( measStop - 1 ).format( 'DD.MM.YYYY' ) : '' ) }</Typography>
			<Paper style={ { margin: '24px 0 0', overflow: 'auto' } }>
				<List dense disablePadding>
				{ meas.slice().reverse().map( ( mea, idx ) =>
					<ListItem divider={ idx !== meas.length - 1 } key={ mea.id }>
						<div style={ { width: '50%' } }>
							<Typography variant="body2" align="center">{ getFormattedDateTime( mea.tsp ) }</Typography>
						</div>
						<div style={ { width: '50%' } }>
							<Typography variant="subtitle2" align="center" style={ { color: ( () => {
								switch( term.cat ) {

									case 1:
									case 2:
										return mea.val === 0 ? theme.palette.green.main : theme.palette.orange.main;

									default:
										return mea.val === 0 ? theme.palette.grey.main : theme.palette.green.main;

								}
							} )() } }>{ ( () => {
								switch( term.cat ) {

									case 1:
									case 2:
										return mea.val === 0 ? 'geschlossen' : 'geöffnet';

									default:
										return mea.val === 0 ? 'ausgeschaltet' : 'eingeschaltet';

								}
							} )() }</Typography>
						</div>
					</ListItem>
				) }
				</List>
			</Paper>
		</div>
	);
} );

 export default connect( state => ( {
		terms:		state.inv.terms,
		measStart:	state.mea.measStart,
		measStop:	state.mea.measStop,
		measAvg:	state.mea.measAvg,
		measMax:	state.mea.measMax,
		measMin:	state.mea.measMin,
		prgrs:		state.gui.prgrs
	} ), dispatch => ( {
		getMeas: ( termId, tsp, dur ) => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 11, typ: 1, data: { termId, tsp, dur } } // MsgCmdMeaHisGet, MsgTypRequest
		} ),
		setVal: ( id, val ) => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 15, typ: 1, data: { id, val } } // MsgCmdTermMeaSet, MsgTypRequest
		} )
	} )
 )( withMobileDialog()( withRouter( TerminalStatus ) ) );