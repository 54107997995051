import {
	Button,
	ButtonGroup,
	Collapse,
	Divider,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Switch,
	TextField,
	Typography
} from '@material-ui/core';
import {
	MyLocation,
	Person,
	VpnKey
} from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';



const Settings = ( {
	plcCfgAct, plcCfgCycle,
	sysPshAct, sysPshUsr, sysPshTkn,
	schedCfgAct, schedCfgLat, schedCfgLng,

	restartPlc, savePlcCfg, saveSchedCfg, saveSysPsh
} ) => {
	const [ plcCfg, setPlcCfg ] = useState( {
		act:	plcCfgAct,
		cycle:	plcCfgCycle
	} );

	const [ sysPsh, setSysPsh ] = useState( {
		act:	sysPshAct,
		usr:	sysPshUsr,
		tkn:	sysPshTkn
	} );

	const [ schedCfg, setSchedCfg ] = useState( {
		act:	schedCfgAct,
		lat:	schedCfgLat.toString().replace( '.', ',' ),
		lng:	schedCfgLng.toString().replace( '.', ',' )
	} );

	return(
		<Fragment>
			<Typography variant="h6" style={ { margin: '24px 0 0' } }>Automation</Typography>
			<Paper>
				<List disablePadding>
					<ListItem>
						<ListItemText primary="Zeitsteuerung" />
						<ListItemSecondaryAction>
							<Switch checked={ schedCfg.act } color="primary" edge="end" onClick={ evt => setSchedCfg( { ...schedCfg, act: evt.target.checked } ) } />
						</ListItemSecondaryAction>
					</ListItem>
					<Collapse in={ schedCfg.act }>
						<List dense>
							<ListItem>
								<TextField placeholder="Breitengrad" inputProps={ { style: { textAlign: 'center' } } } InputProps={ {
									startAdornment: <InputAdornment children={ <MyLocation /> } position="start" />
								} } value={ schedCfg.lat || '' } onChange={ evt => setSchedCfg( { ...schedCfg, lat: evt.target.value } ) } style={ { margin: '0 auto', width: '100%', maxWidth: '500px' } } />
							</ListItem>
							<ListItem>
								<TextField placeholder="Längengrad" inputProps={ { style: { textAlign: 'center' } } } InputProps={ {
									startAdornment: <InputAdornment children={ <MyLocation /> } position="start" />
								} } value={ schedCfg.lng || '' } onChange={ evt => setSchedCfg( { ...schedCfg, lng: evt.target.value } ) } style={ { margin: '0 auto', width: '100%', maxWidth: '500px' } } />
							</ListItem>
						</List>
					</Collapse>
					<Divider />
					<ListItem>
						<ListItemText primary="PLC" />
						<ListItemSecondaryAction>
							<Switch checked={ plcCfg.act } color="primary" edge="end" onClick={ evt => setPlcCfg( { ...plcCfg, act: evt.target.checked }) } />
						</ListItemSecondaryAction>
					</ListItem>
					<Collapse in={ plcCfg.act }>
						<List dense>
							<ListItem>
								<ListItemText primary="Zykluszeit (ms)" />
								<ListItemSecondaryAction>
									<ButtonGroup color="primary" size="small">
										<Button variant={ plcCfg.cycle === 10 ? "contained" : 'outlined' } onClick={ () => setPlcCfg( { ...plcCfg, cycle: 10 } ) }>10</Button>
										<Button variant={ plcCfg.cycle === 25 ? "contained" : 'outlined' } onClick={ () => setPlcCfg( { ...plcCfg, cycle: 25 } ) }>25</Button>
										<Button variant={ plcCfg.cycle === 50 ? "contained" : 'outlined' } onClick={ () => setPlcCfg( { ...plcCfg, cycle: 50 } ) }>50</Button>
										<Button variant={ plcCfg.cycle === 100 ? "contained" : 'outlined' } onClick={ () => setPlcCfg( { ...plcCfg, cycle: 100 } ) }>100</Button>
									</ButtonGroup>
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem style={ { margin: '16px 0 0' } }>
								<ListItemText primary="Neustart nach Initialisierung" />
								<ListItemSecondaryAction>
									<Button color="primary" size="small" variant="outlined" disabled={ !plcCfgAct } onClick={ restartPlc }>Neustart</Button>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Collapse>
				</List>
			</Paper>
			<Typography variant="h6" style={ { margin: '24px 0 0' } }>System</Typography>
			<Paper>
				<List disablePadding>
					<ListItem>
						<ListItemText primary="Pushnachrichten" />
						<ListItemSecondaryAction>
							<Switch checked={ sysPsh.act } color="primary" edge="end" onClick={ evt => setSysPsh( { ...sysPsh, act: evt.target.checked } ) } />
						</ListItemSecondaryAction>
					</ListItem>
					<Collapse in={ sysPsh.act }>
						<List dense>
							<ListItem>
								<TextField placeholder="Pushover-Key" inputProps={ { style: { textAlign: 'center' } } } InputProps={ {
									startAdornment: <InputAdornment children={ <Person /> } position="start" />
								} } value={ sysPsh.usr } onChange={ evt => setSysPsh( { ...sysPsh, usr: evt.target.value } ) } style={ { margin: '0 auto', width: '100%', maxWidth: '500px' } } />
							</ListItem>
							<ListItem>
								<TextField placeholder="Pushover-Token" inputProps={ { style: { textAlign: 'center' } } } InputProps={ {
									startAdornment: <InputAdornment children={ <VpnKey /> } position="start" />
								} } value={ sysPsh.tkn } onChange={ evt => setSysPsh( { ...sysPsh, tkn: evt.target.value } ) } style={ { margin: '0 auto', width: '100%', maxWidth: '500px' } } />
							</ListItem>
						</List>
					</Collapse>
				</List>
			</Paper>
			<div  style={ { margin: '24px 0 0', textAlign: 'right' } }>
				<Button color="primary" onClick={ () => {
					savePlcCfg( plcCfg );
					saveSchedCfg( { ...schedCfg, lat: parseFloat( ( schedCfg.lat ).replace( ',', '.' ) ), lng: parseFloat( schedCfg.lng.replace( ',', '.' ) ) } );
					saveSysPsh( sysPsh );
				} } size="small" variant="contained">speichern</Button>
			</div>
		</Fragment>
	);
}



export default connect( state => ( {
	plcCfgAct:		state.plc.cfg.act,
	plcCfgCycle:	state.plc.cfg.cycle,
	schedCfgAct:	state.sched.cfg.act,
	schedCfgLat:	state.sched.cfg.lat,
	schedCfgLng:	state.sched.cfg.lng,
	sysPshAct:		state.sys.psh.act,
	sysPshUsr:		state.sys.psh.usr,
	sysPshTkn:		state.sys.psh.tkn
} ), dispatch => ( {
	restartPlc: () => {
		dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd:  36, typ:  1 } // MsgCmdPlcStop, MsgTypRequest
		} );
		dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd:  37, typ:  1 } // MsgCmdPlcMemCfgLoad, MsgTypRequest
		} );
		dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd:  38, typ:  1 } // MsgCmdPlcInsLoad, MsgTypRequest
		} );
		dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd:  35, typ:  1 } // MsgCmdPlcStart, MsgTypRequest
		} );
	},
	savePlcCfg: plcCfg => dispatch( {
		type: 'WS_SEND',
		data: { uuid: uuidv4(), cmd:  34, typ:  1, json: JSON.stringify( plcCfg ) } // MsgCmdPlcCfgSet, MsgTypRequest
	} ),
	saveSysPsh: sysPsh => dispatch( {
		type: 'WS_SEND',
		data: {
			uuid: uuidv4(), cmd:  27, typ:  1,
			data: { act: sysPsh.act, name: sysPsh.usr, pw: sysPsh.tkn }
		}
	} ),
	saveSchedCfg: schedCfg => dispatch( {
		type: 'WS_SEND',
		data: {
			uuid: uuidv4(), cmd:  30, typ:  1,
			data: { act: schedCfg.act, lat: schedCfg.lat, lng: schedCfg.lng }
		}
	} )
} ) )( Settings );