import { Terminal } from '../';
import { Chip, Grid, Typography } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';



const TerminalsList = ( { terms } ) => {
	const loc = useLocation();
	const prms = useParams();

	const [ view, setView ] = useState( '' );

	switch( true ) {

		case !!loc.pathname.match( /^\/devs\/\d+\/terms/ ):
			terms = terms.filter( term => term.devId === +prms.devId );
			break;

		case !!loc.pathname.match( /^\/favs\/terms/ ):
			terms = terms.filter( term => !!( term.flgs & 0x100 ) );
			break;

	}

	const tmpTerms = { '': [] };
	for( let term of terms ) {
		const match = term.name.match( /^(.+) - .+$/ );

		if( !match ) {
			tmpTerms[ '' ].push( term );
		} else {
			if( !tmpTerms[ match[ 1 ] ] ) {
				tmpTerms[ match[ 1 ] ] = [ term ];
			} else {
				tmpTerms[ match[ 1 ] ].push( term );
			}
		}
	}

	if( tmpTerms[ '' ].length === 0 ) {
		delete tmpTerms[ '' ];
	}

	if( !terms.length ) {
		return <Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>keine Klemmen hinzugefügt</Typography>;
	} else if( Object.keys( tmpTerms ).length === 1 ) {
		return(
			<div style={ { margin: '24px 0' } }>
				<Grid container spacing={ 1 }>{ terms.map( ( term, idx ) => <Terminal term={ term } key={ idx } /> ) }</Grid>
			</div>
		);
	} else {
		return(
			<Fragment>
				<div style={ { margin: '16px 0 24px' } }>
					<Chip label="Alle" color="primary" variant={ view === '' ? 'default' : 'outlined' } onClick={ () => setView( '' ) } style={ { margin: '8px 8px 0 0' } } />
				{ Object.keys( tmpTerms ).filter( key => key !== '' ).map( ( key, idx ) => (
					<Chip label={ key } color="primary" variant={ key === view ? 'default' : 'outlined' } onClick={ () => setView( key ) } key={ idx } style={ { margin: '8px 8px 0 0' } } />
				) ) }
				</div>
			{ !view &&
				<Fragment>
				{ Object.keys( tmpTerms ).map( ( key, idx ) => (
					<div key={ idx } style={ { margin: '0 0 24px' } }>
					{ !!key &&
						<Typography variant="h6"color="textSecondary">{ key }</Typography>
					}
						<Grid container spacing={ 1 }>{ tmpTerms[ key ].map( ( term, idx ) => <Terminal term={ term } key={ idx } /> ) }</Grid>
					</div>
				) ) }
				</Fragment>
			}
			{ !!view &&
				<div style={ { margin: '0 0 24px' } }>
					<Grid container spacing={ 1 }>{ tmpTerms[ view ].map( ( term, idx ) => <Terminal term={ term } key={ idx } /> ) }</Grid>
				</div>
			}
			</Fragment>
		);
	}
};



export default connect( state => ( {
	terms: state.inv.terms
} ) )( TerminalsList );