import { PLCMember } from '../';
import {
	Grid,
	Typography
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';



const PLCMembersList = ( { act, mems } ) => (
	<Fragment>
	{ !act &&
		<Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>Die PLC ist deaktiviert</Typography>
	}
	{ act && !mems.length &&
		<Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>Keine Teilnehmer vorhanden</Typography>
	}
	{ act && !!mems.length &&
		<Grid container spacing={ 1 }>
			{ mems.map( ( mem, idx ) => <PLCMember mem={ mem } key={ idx } />  ) }
		</Grid>
	}
	</Fragment>
);



export default connect( state => ( {
	act:	state.plc.cfg.act,
	mems:	state.plc.mems
} ) )( PLCMembersList );