import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	Select,
	Slider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Typography,
	withMobileDialog
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const TerminalEditor = ( {
	devs, discTerms, terms, termsDiscAct,
	addTerm, discoverTerms, updateTerm,
	fullScreen, history
} ) => {
	const loc = useLocation();
	const prms = useParams();
	const theme = useTheme();

	const [ term, setTerm ] = useState( () => {
		switch( true ) {

			case !!loc.pathname.match( /^\/devs\/\d+\/terms\/add$/ ):
				return { _disc: true };

			case !!loc.pathname.match( /\/terms\/\d+\/edit$/ ):
				return terms.find( term => term.id === +prms.termId );

		}
	} );

	const [ dev ] = useState( () => {
		switch( true ) {

			case !!loc.pathname.match( /^\/devs\/\d+\/terms\/add$/ ):
				discoverTerms( +prms.devId );
				return devs.find( dev => dev.id === +prms.devId );

			case !!loc.pathname.match( /\/terms\/\d+\/edit$/ ):
				discoverTerms( term.devId );
				return devs.find( dev => dev.id === term.devId );

		}
	} );

	const discTerm = discTerms.find( discTerm => discTerm._hash === term._hash );

	discTerms = discTerms.filter( discTerm => {
		return !terms.find( term => term._hash === discTerm._hash );
	} );

	return(
		<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
			<DialogTitle>{ !term.id ? 'Klemme hinzufügen' : 'Klemme bearbeiten' }</DialogTitle>
			<DialogContent>
			{ term._disc &&
				<div style={ { alignItems: 'center', display: 'flex', margin: '0 0 24px' } }>
					<FormControl fullWidth style={ { flexGrow: '1', margin: '0 8px 0 0' } }>
						<InputLabel id="term">{ termsDiscAct ? 'Suche läuft...' : 'Name' }</InputLabel>
						<Select labelId="term" value={ discTerms.find( discTerm => discTerm._hash === term._hash ) || '' } onChange={ evt => setTerm( evt.target.value ) }>
							{ discTerms.map( term => <MenuItem value={ term } key={ term._hash }>{ term.name }</MenuItem> ) }
						</Select>
						<FormHelperText>{ `${ discTerms.length } Klemmen gefunden` }</FormHelperText>
					</FormControl>
					<Button color="primary" size="small" variant="outlined" disabled={ termsDiscAct } onClick={ () => discoverTerms( dev.id ) }>Suchen</Button>
				</div>
			}
			{ !term._disc &&
				<TableContainer>
					<Table size="small">
						<TableBody>
						{ dev.grp !== 1 &&
							<TableRow>
								<TableCell variant="head" align="right" width="50%">Name</TableCell>
								{ ( () => {
									if( !!discTerm && discTerm.name !== term.name ) {
										return <TableCell style={ { color: theme.palette.orange.main, fontWeight: 'bold' } }>{ discTerm.name }</TableCell>
									} else {
										return <TableCell>{ term.name }</TableCell>;
									}
								} )() }
							</TableRow>
						}
						{ ( term.grp === 1 || term.grp === 4 || term.grp === 6 ) &&
							<TableRow>
								<TableCell variant="head" align="right">Einheit</TableCell>
								<TableCell>{ term.unit || '---' }</TableCell>
							</TableRow>
						}
						<TableRow>
							<TableCell variant="head" align="right" style={ { border: 'none' } }>Gruppe</TableCell>
							<TableCell style={ { border: 'none' } }>{ ( () => {
								switch( term.grp ) {

									case 1:
										return 'analoger Eingang';

									case 2:
										return 'digitaler Eingang';

									case 3:
										return 'digitaler Ausgang';

									case 4:
										return 'Zähler';

									case 5:
										return 'digitaler Ausgang (read-only)';

									case 6:
										return 'analoger Ausgang';

									case 7:
										return 'Farbanzeiger';

									default:
										return 'unbekannt';

								}
							} )() }</TableCell>
						</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			}
			{ !term._disc && dev.grp === 1 &&
				<TextField fullWidth label="Name" onChange={ evt => setTerm( { ...term, name: evt.target.value } ) } value={ term.name } style={ { margin: '24px 0 0' } } />
			}
			{ !term._disc && !!term.grp &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>Optionen</FormLabel>
					<FormControlLabel label="Messwertabfrage aktivieren" control={ <Checkbox color="primary" checked={ !!( term.flgs & 0x2000 ) } onChange={ ( _, val ) =>
						setTerm( { ...term, flgs: !val ? term.flgs & ~0x2000 : term.flgs | 0x2000 } )
					} /> } />
				{ ( term.grp === 1 || term.grp === 6 ) &&
					<FormControlLabel label="Grenzwertüberwachung" control={ <Checkbox color="primary" checked={ !!( term.flgs & 0x800 ) } onChange={ ( _, val ) =>
						setTerm( { ...term, flgs: !val ? term.flgs & ~0x800 : term.flgs | 0x800 } )
					} /> } />
				}
				{ ( term.grp === 2 || term.grp === 3 || term.grp === 5 ) &&
					<FormControlLabel label="Änderungsüberwachung" control={ <Checkbox color="primary" checked={ !!( term.flgs & 0x4000 ) } onChange={ ( _, val ) =>
						setTerm( { ...term, flgs: !val ? term.flgs & ~0x4000 : term.flgs | 0x4000 } )
					} /> } />
				}
					<FormControlLabel label="in Favoriten anzeigen" control={ <Checkbox color="primary" checked={ !!( term.flgs & 0x100 ) } onChange={ ( _, val ) =>
						setTerm( { ...term, flgs: !val ? term.flgs & ~0x100 : term.flgs | 0x100 } )
					} /> } />
				{ ( term.grp === 1 || term.grp === 4 || term.grp === 6 ) &&
					<FormControlLabel label="Messwerte in der W&amp;T-Cloud speichern" control={ <Checkbox color="primary" checked={ !!( term.flgs & 0x1000 ) } onChange={ ( _, val ) =>
						setTerm( { ...term, flgs: !val ? term.flgs & ~0x1000 : term.flgs | 0x1000 } )
					} /> } />
				}
				</FormControl>
			}
			{ !term._disc && ( term.grp === 1 || term.grp === 2 || term.grp === 3 || term.grp === 4 || term.grp === 5 || term.grp === 6 ) &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>Messwerte speichern</FormLabel>
					<div style={ { margin: '0 24px 0' } }>
						<Slider min={ 0 } max={ 31104000 } step={ 2592000 } marks={ [
							{ label: 'Aus', value: 0 },
							{ label: '3 Monate', value: 7776000 },
							{ label: '6 Monate', value: 15552000 },
							{ label: '9 Monate', value: 23328000 },
							{ label: '1 Jahr', value: 31104000 }
						] } value={ term.meaSve } onChange={ ( _, meaSve ) => setTerm( { ...term, meaSve } ) } />
					</div>
				</FormControl>
			}

			{ !term._disc && ( term.grp === 1 || term.grp === 6 ) &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>Grenzwerte</FormLabel>
					<Typography variant="caption" color="textSecondary" align="justify" style={ { margin: '8px 0 0' } }>
						Es wird ein Ereignis aufgezeichnet und eine Push-Nachricht versendet, wenn der Messwert den unteren Grenzwert unterschreitet
						oder den oberen Grenzwert überschreitet. Die Rückkehr des Messwertes in den Toleranzbereich wird ebenfalls aufgezeichnet und gemeldet.
					</Typography>
					<div style={ { alignItems: 'center', display: 'flex', justifyContent: 'flex-start', margin: '8px 0 0' } }>
						<Typography variant="body1" style={ { width: '150px' } }>unterer Grenzwert:</Typography>
						<TextField value={ term.lwrLmt } onChange={ evt => setTerm( { ...term, lwrLmt: evt.target.value } ) } inputProps={ { style: { textAlign: 'center', width: '150px' } } } />
						<Typography variant="body1">{ term.unit }</Typography>
					</div>
					<div style={ { alignItems: 'center', display: 'flex', justifyContent: 'flex-start', margin: '8px 0 0' } }>
						<Typography variant="body1" style={ { width: '150px' } }>oberer Grenzwert:</Typography>
						<TextField value={ term.upprLmt } onChange={ evt => setTerm( { ...term, upprLmt: evt.target.value } ) } inputProps={ { style: { textAlign: 'center', width: '150px' } } } />
						<Typography variant="body1">{ term.unit }</Typography>
					</div>
				</FormControl>
			}

			{ !term._disc && term.grp === 3 &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>bei Systemstart</FormLabel>
					<FormControlLabel label="Zustand nicht ändern" control={ <Radio color="primary" checked={ ( term.flgs & 0x600 ) === 0 } onChange={ () =>
						setTerm( { ...term, valIni: 0, flgs: term.flgs & ~0x600 } )
					} /> } />
					<FormControlLabel label="letzter bekannter Zustand" control={ <Radio color="primary" checked={ ( term.flgs & 0x600 ) === 0x600 } onChange={ () =>
						setTerm( { ...term, valIni: term.val, flgs: term.flgs | 0x600 } )
					} /> } />
					<FormControlLabel label="immer Aus" control={ <Radio color="primary" checked={ !term.valIni && ( term.flgs & 0x600 ) === 0x200 } onChange={ () =>
						setTerm( { ...term, valIni: 0, flgs: ( term.flgs | 0x200 ) & ~0x400 } )
					} /> } />
					<FormControlLabel label="immer An" control={ <Radio color="primary" checked={ !!term.valIni && ( term.flgs & 0x600 ) === 0x200 } onChange={ () =>
						setTerm( { ...term, valIni: 1, flgs: ( term.flgs | 0x200 ) & ~0x400 } )
					} /> } />
				</FormControl>
			}
			{ !term._disc && !!term.batt &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>Batteriewarnung</FormLabel>
					<div style={ { margin: '0 24px 0' } }>
						<Slider min={ 0 } max={ 100 } step={ 5 } marks={ [
							{ label: 'Aus', value: 0 },
							{ label: '25%', value: 25 },
							{ label: '50%', value: 50 },
							{ label: '75%', value: 75 },
							{ label: '100%', value: 100 }
						] } value={ term.battLmt } onChange={ ( _, battLmt ) => setTerm( { ...term, battLmt } ) } />
					</div>
				</FormControl>
			}
			{ !term._disc && term.grp === 1 &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<InputLabel id="termCat">Kategorie</InputLabel>
					<Select labelId="termCat" onChange={ evt => setTerm( { ...term, cat: evt.target.value } ) } value={ term.cat || 0 }>
						<MenuItem value={ 0 }><i>nicht zugeordnet</i></MenuItem>
						<MenuItem value={ 4 }>Temperatur</MenuItem>
						<MenuItem value={ 5 }>Luftfeuchte</MenuItem>
						<MenuItem value={ 6 }>Elektrizität</MenuItem>
						<MenuItem value={ 7 }>Füllstand</MenuItem>
					</Select>
				</FormControl>
			}
			{ !term._disc && term.grp === 2 &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<InputLabel id="termCat">Kategorie</InputLabel>
					<Select labelId="termCat" onChange={ evt => setTerm( { ...term, cat: evt.target.value } ) } value={ term.cat || 0 }>
						<MenuItem value={ 0 }><i>nicht zugeordnet</i></MenuItem>
						<MenuItem value={ 1 }>Tür</MenuItem>
						<MenuItem value={ 2 }>Fenster</MenuItem>
					</Select>
				</FormControl>
			}
			{ !term._disc && term.grp === 3 &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<InputLabel id="termCat">Kategorie</InputLabel>
					<Select labelId="termCat" onChange={ evt => setTerm( { ...term, cat: evt.target.value } ) } value={ term.cat || 0 }>
						<MenuItem value={ 0 }><i>nicht zugeordnet</i></MenuItem>
						<MenuItem value={ 3 }>Beleuchtung</MenuItem>
						<MenuItem value={ 8 }>Pumpe</MenuItem>
					</Select>
				</FormControl>
			}
			{ !term._disc && term.grp === 4 &&
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<InputLabel id="termCat">Kategorie</InputLabel>
					<Select labelId="termCat" onChange={ evt => setTerm( { ...term, cat: evt.target.value } ) } value={ term.cat || 0 }>
						<MenuItem value={ 0 }><i>nicht zugeordnet</i></MenuItem>
						<MenuItem value={ 10 }>Gas</MenuItem>
					</Select>
				</FormControl>
			}
			</DialogContent>
			<DialogActions>
			{ !!term.id &&
				<div style={ { flexGrow: '1', margin: '0 0 0 8px' } }>
					<Button color="secondary" onClick={ () => history.push( 'delete' ) }>löschen</Button>
				</div>
			}
				<div style={ { margin: '0 8px 0 0' } }>
					<Button color="primary" onClick={ history.goBack }>verwerfen</Button>
				{ term._disc &&
					<Button color="primary" disabled={ !term.grp } onClick={ () => {
						switch( term.grp ) {

							case 1:
							case 6:
								setTerm( { ...term, lwrLmt: 0, upprLmt: 100, meaSve: 0, _disc: false } );
								break;

							default:
								setTerm( { ...term, meaSve: 0, _disc: false } );

						}
				} }>weiter</Button>
				}
				{ !term._disc && !term.id &&
					<Button color="primary" onClick={ () => {
						addTerm( term );
						history.goBack();
					} }>hinzufügen</Button>
				}
				{ !term._disc && !!term.id &&
					<Button color="primary" onClick={ () => {
						updateTerm( { ...term, name: !!discTerm && discTerm.name !== term.name ? discTerm.name : term.name } );
						history.goBack();
					} }>speichern</Button>
				}
				</div>
			</DialogActions>
		</Dialog>
	);
};



 export default connect(
	state => ( {
		devs:			state.inv.devs,
		discTerms:		state.gui.discTerms,
		terms:			state.inv.terms,
		termsDiscAct:	state.gui.termsDiscAct
	} ), dispatch => ( {
		addTerm: term => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 7, typ: 1, data: { ...term, lwrLmt: parseFloat( term.lwrLmt ) || 0, upprLmt: parseFloat( term.upprLmt ) || 100 } }  // MsgCmdTermCfgAdd, MsgTypRequest
		} ),
		discoverTerms: id => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 3, typ: 1, data: { id } } // MsgCmdTermDisc, MsgTypRequest
		} ),
		updateTerm: term => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 10, typ: 1, data: { ...term, lwrLmt: parseFloat( term.lwrLmt ) || 0, upprLmt: parseFloat( term.upprLmt ) || 100 } } // MsgCmdTermCfgSet, MsgTypRequest
		} )
	} )
 )( withRouter( withMobileDialog()( TerminalEditor ) ) );