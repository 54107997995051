import { Card, CardHeader, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Edit, MoreVert } from '@material-ui/icons';
import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import bulb_off from '../../_images/bulb_off_048_048_000000.png';
import bulb_on from '../../_images/bulb_on_048_048_000000.png';
import door_closed from '../../_images/door_closed_048_048.png';
import door_opened from '../../_images/door_opened_048_048.png';
import drop from '../../_images/drop_048_048_000000.png';
import electricity from '../../_images/electricity_048_048.png';
import gas from '../../_images/gas_048_048.png';
import level_empty from '../../_images/level_empty_048_048.png';
import level_full from '../../_images/level_full_048_048.gif';
import pump_off from '../../_images/pump_off_048_048.png';
import pump_on from '../../_images/pump_on_048_048.gif';
import temperature from '../../_images/temperature_048_048_000000.png';
import window_closed from '../../_images/window_closed_048_048.png';
import window_opened from '../../_images/window_opened_048_048.png';



const Terminal = memo( ( { term } ) => {
	const theme = useTheme();

	const [ anchor, setAnchor ] = useState( null );

	return(
		<Grid item xs={ 12 } sm={ 6 } md={ 4 } component={ Link } to={ `terms/${ term.id }/status` } style={ { textDecoration: 'none' } }>
			<Card style={ { backgroundColor: ( () => {
				switch( true ) {

					case term.valTsp === 0:
					case !( term.flgs & 0x2000 ):
						return theme.palette.orange.light;

					case !!( term.flgs & 0x1 ):
						return theme.palette.red.light;

					case term.cat === 1: // TermCatDoor
					case term.cat === 2: // TermCatWindow
						return term.val === 0 ? theme.palette.green.light : theme.palette.orange.light;

					case term.cat === 3: // TermCatLight
						return term.val === 0 ? theme.palette.grey.light : theme.palette.yellow.light;

					case term.cat === 4: // TermCatTemperature
						return theme.palette.purple.light;

					case term.cat === 5: // TermCatHumidity
					case term.cat === 7: // TermCatLevel
						return theme.palette.blue.light;

					case term.cat === 6: // TermCatElectricity
					case term.cat === 10: // TermCatGas
						return theme.palette.yellow.light;

					case term.grp === 2:
					case term.grp === 3:
					case term.grp === 5:
						return term.val === 0 ? theme.palette.grey.light : theme.palette.green.light;

					case term.grp === 7:
						return '#' + ( '000000' + term.val.toString( 16 ) ).slice( -6 );

					default:
						return '#ffffff';

				}
			} )() } }>
				<CardHeader
					title={ term.name.split( ', ', 2 )[ 0 ] }
					titleTypographyProps={ { variant: 'body2' } }
					subheader={ term.name.split( ', ', 2 )[ 1 ] || null }
					subheaderTypographyProps={ { color: 'textPrimary', variant: 'body2' } }
					action={ <IconButton children={ <MoreVert /> } onClick={ evt => {
						evt.preventDefault();
						setAnchor( evt.currentTarget );
					} } /> }
				/>
				<div style={ { alignItems: 'center', display: 'flex', padding: '0 16px 16px' } }>
					<div style={ { height: '48px', margin: '2px 0' } }>
					{ ( () => {
						switch( true ) {

							case term.cat === 1 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ door_closed } />

							case term.cat === 1 && !!term.val:
								return <img alt='' src={ door_opened } />

							case term.cat === 2 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ window_closed } />

							case term.cat === 2 && !!term.val:
								return <img alt='' src={ window_opened } />

							case term.cat === 3 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ bulb_off } />

							case term.cat === 3 && !!term.val:
								return <img alt='' src={ bulb_on } />

							case term.cat === 4:
								return <img alt='' src={ temperature } />

							case term.cat === 5:
								return <img alt='' src={ drop } />

							case term.cat === 6:
								return <img alt='' src={ electricity } />

							case term.cat === 7 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ level_empty } />

							case term.cat === 7:
								return <img alt='' src={ level_full } />

							case term.cat === 8 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ pump_off } />

							case term.cat === 8:
								return <img alt='' src={ pump_on } />

							case term.cat === 10:
								return <img alt='' src={ gas } />

							default:
								return null;

						}
					} )() }
					</div>
					<div style={ { flexGrow: '1', textAlign: 'center' } }>
						<Typography variant="h6">
						{ ( () => {
							switch( true ) {

								case !( term.flgs & 0x2000 ):
									return 'inaktiv';

								case !!( term.flgs & 0x1 ):
									return 'kein Messwert';

								case term.valTsp === 0:
									return 'warte auf Messwert';

								case term.cat === 1: // TermCatDoor
								case term.cat === 2: // TermCatWindow
									return term.val === 0 ? 'geschlossen' : 'geöffnet';

								case term.grp === 1:
								case term.grp === 4:
								case term.grp === 6:
									return `${ term.val.toFixed( 1 ).replace( '.', ',' ) }${ term.unit }`;

								case term.grp === 2:
								case term.grp === 3:
								case term.grp === 5:
									return term.val === 0 ? 'ausgeschaltet' : 'eingeschaltet';

								case term.grp === 7:
									return '#' + ( '000000' + term.val.toString( 16 ) ).slice( -6 );

								default:
									return `${ term.val }${ term.unit } (${ term.grp })`;

							}
						} )() }
						</Typography>
					{ !( term.flgs & 0x1 ) && !!( term.flgs & 0x800 ) && !!( term.flgs & 0x2000 ) && ( term.val < term.lwrLmt || term.upprLmt < term.val ) &&
						<Typography color="error" variant="caption">Grenzwertverletzung</Typography>
					}
					{ !( term.flgs & 0x1 ) && !!( term.flgs & 0x2000 ) && term.batt < term.battLmt &&
						<Typography color="error" variant="subtitle2">Batteriewarnung</Typography>
					}
					</div>
				</div>
			</Card>
			<Menu anchorEl={ anchor } onClick={ evt => {
				evt.preventDefault();
				setAnchor( null );
			} } open={ !!anchor }>
				<MenuItem component={ Link } to={ `terms/${ term.id }/edit` }>
					<ListItemIcon children={ <Edit /> } />
					<ListItemText primary="Bearbeiten" />
				</MenuItem>
			</Menu>
		</Grid>
	);
} );



export default Terminal;