import { Button, InputAdornment, TextField } from '@material-ui/core';
import { LockOutlined as Lock } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import house_128_128_0080c8 from './_images/house_128_128_0080c8.png';



const LoginDialog = ( { err, login } ) => {
	const [ pw, setPw ] = useState( '' );

	return(
		<div style={ { margin: '25vH 0 0', textAlign: 'center' } }>
			<img alt="Home" src={ house_128_128_0080c8 } />
			<div style={ { margin: '48px auto', width: '250px' } }>
				<TextField
					error={ err }
					fullWidth
					InputProps={ { startAdornment: <InputAdornment children={ <Lock /> } position="start" /> } }
					onChange={ evt => setPw( evt.target.value ) }
					onKeyPress={ evt => evt.key === 'Enter' ? login( pw ) : null }
					placeholder="Passwort"
					type="password"
					value={ pw }
				/>
			</div>
			<Button color="primary" variant="contained" onClick={ () => login( pw ) }>Anmelden</Button>
		</div>
	);
};



export default connect( null, dispatch => ( {
	login: pw => dispatch( { type: 'SYS_LOGIN', data: { pw } } )
} ) )( LoginDialog );