import {
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography
} from '@material-ui/core';
import {
	Code,
	ExitToApp,
	FormatListBulleted,
	Schedule,
	Settings,
	Star,
	Widgets
} from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import house_048_048_666666 from './_images/house_048_048_666666.png';



const MainMenu = ( {
	menuOpen, sysVer,
	closeMenu, logout
} ) => (
	<Drawer open={ menuOpen } onClose={ closeMenu }>
		<img alt="Home" src={ house_048_048_666666 } style={ { margin: '48px auto 32px' } } />
		<div style={ { display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', margin: '0 0 8px' } }>
			<List onClick={ closeMenu }>
				<ListItem button component={ Link } to="/favs/terms">
					<ListItemIcon children={ <Star /> } />
					<ListItemText primary="Favoriten" />
				</ListItem>
				<ListItem button component={ Link } to="/devs">
					<ListItemIcon children={ <Widgets /> } />
					<ListItemText primary="Geräte" />
				</ListItem>
				<ListItem button component={ Link } to="/sched">
					<ListItemIcon children={ <Schedule /> } />
					<ListItemText primary="Zeitsteuerung" />
				</ListItem>
				<ListItem button component={ Link } to="/plc">
					<ListItemIcon children={ <Code /> } />
					<ListItemText primary="PLC" />
				</ListItem>
				<ListItem button component={ Link } to="/log">
					<ListItemIcon children={ <FormatListBulleted /> } />
					<ListItemText primary="Ereignisse" />
				</ListItem>
			</List>
			<List onClick={ closeMenu }>
				<ListItem button component={ Link } to="/settings">
					<ListItemIcon children={ <Settings /> } />
					<ListItemText primary="Einstellungen" />
				</ListItem>
				<ListItem button onClick={ logout }>
					<ListItemIcon children={ <ExitToApp /> } />
					<ListItemText primary="Abmelden" />
				</ListItem>
			</List>
			<Typography variant="caption" display="block" align="center">HomeHub { sysVer }<br />HomeGUI v1.10.00</Typography>
		</div>
	</Drawer>
);



export default connect( state => ( {
	menuOpen:	state.gui.menuOpen,
	sysVer:		state.sys.ver
} ), dispatch => ( {
	closeMenu: () => dispatch( {
		type: 'GUI_MENU_OPEN',
		data: { menuOpen: false }
	} ),
	logout: () => dispatch( {
		type: 'WS_SEND',
		data: { uuid: uuidv4(), cmd: 20, typ: 1 } // MsgCmdSysLogout, MsgTypRequest
	} )
} ) )( MainMenu );