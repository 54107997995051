import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	withMobileDialog
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const DeviceDelete = ( {
	devs,
	deleteDev,
	fullScreen, history
} ) => {
	const prms = useParams();
	const dev = devs.find( dev => dev.id === +prms.devId );

	return(
		<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
			<DialogTitle>Klemme löschen</DialogTitle>
			<DialogContent>
				<Typography variant="body1" align="center">Soll das Gerät <b>{ dev.name }</b> endgültig gelöscht werden?</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={ history.goBack }>abbrechen</Button>
				<Button color="secondary" onClick={ () => {
					deleteDev( dev.id );
					history.go( -2 );
				} }>löschen</Button>
			</DialogActions>
		</Dialog>
	);
};



 export default connect(
	state => ( {
		devs: state.inv.devs
	} ), dispatch => ( {
		deleteDev: id => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 2, typ: 1, data: { id } } // MsgCmdDevCfgDel, MsgTypeRequest
		} )
	} )
 )( withRouter( withMobileDialog()( DeviceDelete ) ) );