import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	withMobileDialog
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const ScheduleItemDelete = ( {
	itms, terms,
	deleteSchedItm,
	fullScreen, history
} ) => {
	const prms = useParams();

	const itm = itms.find( itm => itm.id === +prms.itmId );
	const term = terms.find( term => term.id === itm.termId );

	return(
		<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
			<DialogTitle>Eintrag löschen</DialogTitle>
			<DialogContent>
				<Typography variant="body1" align="center">Soll der Eintrag für die Klemme <b>{ term.name }</b> endgültig gelöscht werden?</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={ history.goBack }>abbrechen</Button>
				<Button color="secondary" onClick={ () => {
					deleteSchedItm( itm.id );
					history.go( -2 );
				} }>löschen</Button>
			</DialogActions>
		</Dialog>
	);
};



 export default connect(
	state => ( {
		terms:	state.inv.terms,
		itms:	state.sched.itms
	} ), dispatch => ( {
		deleteSchedItm: id => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 25, typ: 1, data: { id } } // MsgCmdSchedItmDel, MsgTypRequest
		} ),
	} )
 )( withRouter( withMobileDialog()( ScheduleItemDelete ) ) );