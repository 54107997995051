import {
	Chip,
	List,
	ListItem,
	Paper,
	Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormattedDateTime } from '../../utils';
import { v4 as uuidv4 } from 'uuid';



const LogItemsList = ( { itms, flgs, prgrs, getLogItms } ) => {
	const theme = useTheme();

	useEffect( () => getLogItms( flgs ), [] );

	if( prgrs !== 100 ) {
		return(
			<div style={ { margin: '10vH 0 0' } }>
				<Typography variant="h6" align="center">Ereignisse werden geladen</Typography>
				<Typography variant="subtitle1" align="center">{ prgrs }%</Typography>
			</div>
		);
	} else {
		return(
			<Fragment>
				<Chip label="Hinweis" color={ !( flgs & 0x2 ) ? 'default' : 'primary' } onClick={ () => getLogItms( flgs ^ 0x02 ) } style={ { margin: '24px 8px 0 0' } } />
				<Chip label="Warnung" color={ !( flgs & 0x4 ) ? 'default' : 'primary' } onClick={ () => getLogItms( flgs ^ 0x04 ) } style={ { margin: '24px 8px 0 0' } } />
				<Chip label="Fehler" color={ !( flgs & 0x8 ) ? 'default' : 'primary' } onClick={ () => getLogItms( flgs ^ 0x08 ) } style={ { margin: '24px 8px 0 0' } } />
				<Chip label="Debug" color={ !( flgs & 0x1 ) ? 'default' : 'primary' } onClick={ () => getLogItms( flgs ^ 0x01 ) } style={ { margin: '24px 0 0' } } />
				{ itms.length === 0 &&
					<Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>keine Ereignisse aufgezeichnet</Typography>
				}
				{ itms.length !== 0 &&
					<Paper style={ { margin: '24px 0' } }>
						<List dense disablePadding>
						{ itms.slice().reverse().map( ( itm, idx ) => 
							<ListItem divider={ idx !== itms.length - 1 } key={ itm.id }>
								<div>
									<Typography variant="body2" align="center" style={ { width: '100px' } }>{ getFormattedDateTime( itm.tsp ) }</Typography>
								</div>
							{ !!( itm.flgs & 0x0e ) &&
								<div>
									<Typography variant="body2">{ itm.name }</Typography>
									<Typography variant="subtitle2" style={ { color: ( () => {
										switch( itm.flgs & 0x0e ) {

											case 0x02:
												return theme.palette.green.main;

											case 0x04:
												return theme.palette.orange.main;

											case 0x08:
												return theme.palette.red.main;

										}
									} )() } }>{ itm.txt }</Typography>
								</div>
							}
							{ !!( itm.flgs & 0x01 ) &&
								<div>
									<Typography variant="subtitle2">{ itm.name }</Typography>
									<Typography variant="body2">{ itm.txt }</Typography>
								</div>
							}
							</ListItem>
						) }
						</List>
					</Paper>
				}
			</Fragment>
		);
	}
};



 export default connect(
	state => ( {
		itms:	state.log.itms,
		flgs:	state.log.flgs,
		prgrs:	state.gui.prgrs
	} ), dispatch => ( {
		getLogItms:	flgs => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd:  31, typ: 1, data: { flgs } } // MsgCmdLogItmGet, MsgTypRequest
		} )
	} )
 )( LogItemsList );