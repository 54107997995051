import {
	Button,
	ButtonGroup,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	InputLabel,
	MenuItem,
	Select,
	Radio,
	RadioGroup,
	Slider,
	withMobileDialog
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import moment, { unix } from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const ScheduleItemEditor = ( { 
	itms, terms,
	add, set,
	fullScreen, history
} ) => {
	const location = useLocation();
	const params = useParams();

	const [ itm, setItm ] = useState( () => {
		let match;

		switch( true ) {

			case location.pathname.match( /^\/sched\/\d+\/edit$/ ) !== null:
				for( let itm of itms ) {
					if( itm.id === +params.itmId ) {
						return itm;
					}
				}

				return {
					id:		0,
					termId:	0,
					tsp:	Math.ceil( moment().unix() / 60 ) * 60,
					dly:	0,
					val:	0,
					flgs:	0
				};

			case location.pathname === '/sched/add' && ( match = location.search.match( /^\?termId=(\d+)$/ ) ) !== null:
				return {
					id:		0,
					termId:	+match[ 1 ],
					tsp:	Math.ceil( moment().unix() / 60 ) * 60,
					dly:	0,
					val:	0,
					flgs:	0
				};

			default:
				return {
					id:		0,
					termId:	0,
					tsp:	Math.ceil( moment().unix() / 60 ) * 60,
					dly:	0,
					val:	0,
					flgs:	0
				};

		}
	} );

	return(
		<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
			<DialogTitle>{ !itm.id ? 'Eintrag hinzufügen' : 'Eintrag bearbeiten' }</DialogTitle>
			<DialogContent>
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<InputLabel id="itmTermId">Klemme</InputLabel>
					<Select labelId="itmTermId" onChange={ evt => setItm( { ...itm, termId: evt.target.value } ) } value={ itm.termId || '' }>
						{ terms.map( term => term.grp === 3 ? <MenuItem key={ [ 'term-', term.id ] } value={ term.id }>{ term.name }</MenuItem> : null ) }
					</Select>
				</FormControl>
				<FormControl style={ { margin: '24px 0 0' } }>
					<FormLabel>Ausführung</FormLabel>
					<RadioGroup value={ itm.flgs & 0x600 } onChange={ evt => setItm( { ...itm, flgs: ( itm.flgs & ~0x600 ) | evt.target.value } ) }>
						<FormControlLabel label="nach Vorgabe" control={ <Radio color="primary" /> } value={ 0x0 } />
						<FormControlLabel label="beim nächsten Sonnenaufgang" control={ <Radio color="primary" /> } value={ 0x200 } />
						<FormControlLabel label="beim nächsten Sonnenuntergang" control={ <Radio color="primary" /> } value={ 0x400 } />
					</RadioGroup>
				</FormControl>
			{ !( itm.flgs & 0x600 ) &&
				<FormControl fullWidth style={ { margin: '16px 0 0' } }>
					<FormLabel>Zeitpunkt</FormLabel>
					<DateTimePicker
						format="DD.MM.YYYY, HH:mm"
						ampm={ false }
						hideTabs={ true }
						value={ unix( itm.tsp ) }
						onChange={ date => setItm( { ...itm, tsp: date.unix() } ) }
					/>
				</FormControl>
			}
			{ !!( itm.flgs & 0x600 ) &&
				<FormControl fullWidth style={ { margin: '16px 0 0' } }>
					<FormLabel>Versatz</FormLabel>
					<div style={ { margin: '0 24px 0' } }>
						<Slider
							min={ -3600 }
							max={ 3600 }
							step={ 60 }
							marks={ [
								{ label: '-1h', value: -3600 },
								{ label: '-30m', value: -1800 },
								{ label: '0', value: 0 },
								{ label: '+30m', value: 1800 },
								{ label: '+1h', value: 3600 }
							] }
							value={ itm.dly }
							valueLabelDisplay="auto"
							valueLabelFormat={ dly => ( dly < 0 ? '-' : ( dly > 0 ? '+' : '' ) ) + ( dly / 60 ) }
							onChange={ ( _, dly ) => setItm( { ...itm, dly } ) }
						/>
					</div>
				</FormControl>
			}
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>Zustand</FormLabel>
					<ButtonGroup fullWidth size="small" style={ { margin: '8px 0 0' } }>
						<Button variant={ itm.val ? 'outlined' : 'contained' } color={ itm.val ? 'default' : 'primary' } onClick={ () => setItm( { ...itm, val: 0 } ) }>ausschalten</Button>
						<Button variant={ itm.val ? 'contained' : 'outlined' } color={ itm.val ? 'primary' : 'default' } onClick={ () => setItm( { ...itm, val: 1 } ) }>einschalten</Button>
					</ButtonGroup>
				</FormControl>
				<FormControl fullWidth style={ { margin: '24px 0 0' } }>
					<FormLabel>Optionen</FormLabel>
					<FormGroup>
						<FormControlLabel label="täglich" control={ <Checkbox checked={ ( itm.flgs & 0x100 ) !== 0 } onChange={ ( _, val ) => setItm( { ...itm, flgs: !val ? itm.flgs & ~0x100 : itm.flgs | 0x100 } ) } color="primary" /> } />
						<FormControlLabel label="aktiv" control={ <Checkbox checked={ ( itm.flgs & 0x800 ) !== 0 } onChange={ ( _, val ) => setItm( { ...itm, flgs: !val ? itm.flgs & ~0x800 : itm.flgs | 0x800 } ) } color="primary" /> } />
					</FormGroup>
				</FormControl>
			</DialogContent>
			<DialogActions>
			{ itm.id !== 0 &&
				<div style={ { flexGrow: '1', margin: '0 0 0 8px' } }>
					<Button color="secondary" onClick={ () => history.push( 'delete' ) }>löschen</Button>
				</div>
			}
				<div style={ { margin: '0 8px 0 0' } }>
					<Button color="primary" onClick={ history.goBack }>verwerfen</Button>
					<Button color="primary" disabled={ !itm.termId } onClick={ () => {
						!( itm.flgs & 0x600 ) ? itm.dly = 0 : itm.tsp = 0;
						!itm.id ? add( itm ) : set( itm );
						history.goBack();
					} }>speichern</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};



export default  connect(
	state => ( {
		itms:	state.sched.itms,
		terms:	state.inv.terms
	} ), dispatch => ( {
		add: itm => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 24, typ: 1, data: itm } // MsgCmdSchedItmAdd, MsgTypRequest
		} ),
		set: itm => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 23, typ: 1, data: itm } // MsgCmdSchedItmSet, MsgTypRequest
		} )
	} )
)( withRouter( withMobileDialog()( ScheduleItemEditor ) ) );