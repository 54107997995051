import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Edit, MoreVert, Replay } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';



const Device = ( { dev, restartDev } ) => {
	const theme = useTheme();

	const [ anchor, setAnchor ] = useState( null );

	return(
		<Grid item xs={ 12 } sm={ 6 } md={ 4 } component={ Link } to={ `/devs/${ dev.id }/terms` } style={ { textDecoration: 'none' } }>
			<Card style={ { backgroundColor: ( () => {
				switch( dev.flgs & 0xff ) {

					case 0x01:
					case 0x02:
					case 0x04:
					case 0x08:
					case 0x40:
						return theme.palette.red.light;

					case 0x10:
						return theme.palette.orange.light;

					default:
						return theme.palette.green.light;

				}
			} )() } }>
				<CardHeader
					title={ dev.name }
					titleTypographyProps={ { variant: 'body2' } }
					subheader={ dev.uri }
					subheaderTypographyProps={ { color: 'textSecondary', variant: 'body2' } }
					action={ <IconButton children={ <MoreVert /> } onClick={ evt => {
						evt.preventDefault();
						setAnchor( evt.currentTarget );
					} } /> }
				/>
				<CardContent>
					<Typography align="center" variant="h6">
					{ ( () => {
						switch( true ) {

							case !!( dev.flgs & 0x01 ):
								return 'Passwort erforderlich';

							case !!( dev.flgs & 0x02 ):
								return 'Web-API dekativiert';

							case !!( dev.flgs & 0x04 ):
								return 'nicht erreichbar';

							case !!( dev.flgs & 0x08 ):
								return 'unbekannter Fehler';

							case !!( dev.flgs & 0x10 ):
								return 'öffne serielle Schnittstelle';

							case !!( dev.flgs & 0x40 ):
								return 'serielle Schnittstelle geschlossen';

							case !!( dev.flgs & 0x20 ):
							case !( dev.flgs & 0xff ):
								return 'betriebsbereit';

							default:
								return dev.flgs;

						}
					} )() }
					</Typography>
				</CardContent>
			</Card>
			<Menu anchorEl={ anchor } onClick={ evt => {
				evt.preventDefault();
				setAnchor( null );
			} } open={ !!anchor }>
				<MenuItem component={ Link } to={ `/devs/${ dev.id }/edit` }>
					<ListItemIcon children={ <Edit /> } />
					<ListItemText primary="Bearbeiten" />
				</MenuItem>
			{ dev.grp === 4 &&
				<MenuItem onClick={ restartDev }>
					<ListItemIcon children={ <Replay /> } />
					<ListItemText primary="Neustart" />
				</MenuItem>
			}
			</Menu>
		</Grid>
	);
};



export default connect( null, ( dispatch, ownProps ) => ( {
	restartDev: () => dispatch( {
		type: 'WS_SEND',
		data: { uuid: uuidv4(), cmd:  28, typ: 1, data: { id: ownProps.dev.id } } // MsgCmdDevRst, MsgTypRequest
	} )
} ) )( Device );