import App from './app';
import automator from './automator';
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { reducer } from './reducer';
import { applyMiddleware, createStore } from 'redux';
import 'typeface-roboto';
//import registerServiceWorker from './registerServiceWorker';



const store = createStore( reducer, applyMiddleware( automator ) );

const theme = createTheme( {
	palette: {
		error: {
			main: 		'#e30613'
		},
		primary: {
			main:		'#0080c8'
		},
		text: {
			secondary:	'#9d9c9c'
		},

		blue: {
			light:		'#a1ddff',
			main:		'#0080c8'
		},
		green: {
			light:		'#b0f9c6',
			main:		'#009640'
		},
		grey: {
			light:		'#e5e5e5',
			main:		'#9d9c9c',
			dark:		'#666666'
		},
		orange: {
			light:		'#fad5a4',
			main:		'#f28f0e'
		},
		purple: {
			light:		'#fc9ffb',
			main:		'#b507b3'
		},
		red: {
			light:		'#ffcdce',
			main:		'#e30613'
		},
		yellow: {
			light:		'#ffffc0',
			main:		'#fcdc4b',
			dark:		'#cea902'
		}
	}
} );



ReactDOM.render(
	<Provider store={ store }>
		<MuiThemeProvider theme={ theme }>
			<MuiPickersUtilsProvider utils={ MomentUtils }>
				<MemoryRouter initialEntries={ [ '/favs/terms' ] } >
					<CssBaseline />
					<App />
				</MemoryRouter>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	</Provider>,
	document.getElementById( 'root' )
);



store.dispatch( { type: 'WS_INIT' } );

//registerServiceWorker();