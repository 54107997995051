import { Chip, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Brightness2Outlined as Moon, ErrorOutline as Error, Repeat, Schedule, WbSunnyOutlined as Sun } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getFormattedDateTime, getFormattedDuration } from '../../utils';



const ScheduleItem = ( { itm, term } ) => {
	const theme = useTheme();
/*
	const [ cnt, setCnt ] = useState( itm.tsp + itm.dly - moment().unix() );

	useEffect( () => {
		const intvl = setInterval( () => setCnt( cnt - 1 ) , 1000 );
		return () => clearInterval( intvl );
	}, [ cnt ] );
*/
	return(
		<ListItem button divider component={ Link } to={ `/sched/${ itm.id }/edit` } style={ { padding: '4px 8px' } }>
			<ListItemText
				primary={
					<div style={ { alignItems: 'center', display: 'flex' } }>
						<div style={ { flexGrow: '1' } }>
						{ ( !( itm.flgs & 0x600 ) || ( !!( itm.flgs & 0x600 ) && ( ( !!itm.tsp && !( itm.flgs & 0x1 ) ) || ( !!( itm.flgs & 0x1 ) && !( itm.flgs & 0x100 ) ) ) ) ) &&
							<Typography variant="body2" display="inline">{ getFormattedDateTime( itm.tsp + itm.dly ) }</Typography>
						}
						{ !!( itm.flgs & 0x600 ) && ( !itm.tsp || ( !!( itm.flgs & 0x1 ) && !!( itm.flgs & 0x100 ) ) ) &&
							<Typography variant="body2" display="inline">warte auf Zeitzuweisung</Typography>
						}
						{ !( itm.flgs & 0x800 ) &&
							<Chip icon={ <Error style={ { color: '#ffffff' } } /> } label="inaktiv" size="small" style={ { backgroundColor: theme.palette.orange.main, color: '#ffffff', marginLeft: '8px' } } />
						}
						</div>
						<div>
						{ ( () => {
							switch( true ) {

								case term.cat === 3 && !itm.val:
									return <Chip label="ausschalten" size="small" style={ { backgroundColor: theme.palette.grey.light } } />;

								case term.cat === 3 && !!itm.val:
									return <Chip label="einschalten" size="small" style={ { backgroundColor: theme.palette.yellow.main } } />;

								case !itm.val:
									return <Chip label="ausschalten" size="small" style={ { backgroundColor: theme.palette.grey.light } } />;

								case !!itm.val:
									return <Chip label="einschalten" size="small" style={ { backgroundColor: theme.palette.green.light } } />;

							}
						} )() }
						</div>
					</div>
				}
				primaryTypographyProps={ { color: !!( itm.flgs & 0x800) && ( !( itm.flgs & 0x1 ) || !!( itm.flgs & 0x100 ) ) ? 'textPrimary' : 'textSecondary', gutterBottom: true } }
				secondary={
					<Fragment>
					{ !( itm.flgs & 0x600 ) &&
						<Chip icon={ <Schedule /> } label="Vorgabe" color="primary" size="small" />
					}
					{ !!( itm.flgs & 0x200 ) &&
						<Chip icon={ <Sun /> } label={ `Sonnenaufgang${ !itm.dly ? '' : ' (' + getFormattedDuration( itm.dly ) + ')' }` } color="primary" size="small" />
					}
					{ !!( itm.flgs & 0x400 ) &&
						<Chip icon={ <Moon /> } label={ `Sonnenuntergang${ !itm.dly ? '' : ' (' + getFormattedDuration( itm.dly ) + ')' }` } color="primary" size="small" />
					}
					{ !!( itm.flgs & 0x100 ) &&
						<Chip icon={ <Repeat /> } label="täglich" color="primary" size="small" style={ { marginLeft: '8px' } } />
					}
					</Fragment>
				}
				secondaryTypographyProps={ { component: 'span' } }
			/>
		</ListItem>
	);
};



export default ScheduleItem;