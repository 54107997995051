import { ScheduleItem } from '../';
import { Card, CardHeader, Grid, IconButton, List, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import bulb_off from '../../_images/bulb_off_048_048_000000.png';
import bulb_on from '../../_images/bulb_on_048_048_000000.png';
import pump_off from '../../_images/pump_off_048_048.png';
import pump_on from '../../_images/pump_on_048_048.gif';



const ScheduleTerminal = ( { itms, term } ) => {
	const theme = useTheme();

	return(
		<Grid item xs={ 12 }>
			<Card style={ { backgroundColor: ( () => {
				switch( true ) {

					case !( term.flgs & 0x2000 ):
						return theme.palette.orange.light;

					case !!( term.flgs & 0x1 ):
						return theme.palette.red.light;

					case !term.valTsp:
						return theme.palette.orange.light;

					case term.cat === 3: // TermCatLight
						return term.val === 0 ? theme.palette.grey.light : theme.palette.yellow.light;

					case term.grp === 3: // TermGrpDigOut
						return term.val === 0 ? theme.palette.grey.light : theme.palette.green.light;

					default:
						return '#fff';

				}
			} )() } }>
				<CardHeader
					avatar={ ( () => {
						switch( true ) {

							case term.cat === 3 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ bulb_off } style={ { verticalAlign: 'bottom' } } />;

							case term.cat === 3 && !!term.val:
								return <img alt='' src={ bulb_on } style={ { verticalAlign: 'bottom' } } />;

							case term.cat === 8 && ( !term.val || !( term.flgs & 0x2000 ) || !!( term.flgs & 0x1 ) ):
								return <img alt='' src={ pump_off } style={ { verticalAlign: 'bottom' } } />;

							case term.cat === 8:
								return <img alt='' src={ pump_on } style={ { verticalAlign: 'bottom' } } />;

							default:
								return null;

						}
					} )() }
					title={ term.name.split( ', ', 2 )[ 0 ] }
					titleTypographyProps={ { variant: 'body2' } }
					subheader={ term.name.split( ', ', 2 )[ 1 ] || null }
					subheaderTypographyProps={ { color: 'textPrimary', variant: 'body2' } }
					action={ <IconButton children={ <Add /> } component={ Link } to={ { pathname: '/sched/add', search: `?termId=${ term.id }` } } /> }
				/>
				<Paper style={ { margin: '0 8px 8px' } }>
					<List dense disablePadding>
						{ itms.map( itm => itm.termId === term.id ? <ScheduleItem itm={ itm } term={ term } key={ itm.id } /> : null ) }
					</List>
				</Paper>
			</Card>
		</Grid>
	);
};



export default connect( state => ( {
	itms: state.sched.itms
} ) )( ScheduleTerminal );