import { ScheduleTerminal } from '../';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';



const ScheduleTerminalsList = ( { act, itms, terms } ) => {
	if( !act ) {
		return <Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>Die Zeitsteuerung ist deaktiviert</Typography>;
	} else if( itms.length === 0 ) {
		return <Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>Es sind keine Einträge vorhanden</Typography>;
	} else {
		return(
			<Grid container spacing={ 1 } style={ { margin: '20px -4px' } }>
			{ terms.map( term => {
				for( let itm of itms ) {
					if( itm.termId === term.id ) {
						return <ScheduleTerminal term={ term } key={ term.id } />;
					}
				}

				return null;
			} ) }
			</Grid>
		);
	}
};



export default connect( state => ( {
	act:	state.sched.cfg.act,
	itms:	state.sched.itms,
	terms:	state.inv.terms
} ) )( ScheduleTerminalsList );