import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { getFormattedDateTime } from '../../utils';



const PLCMember = ( { mem, terms } ) => {
	const theme = useTheme();

	return(
		<Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 4 }>
			<Card style={ { backgroundColor: ( () => {
				switch( mem.grp ) {

					case 1: // MemGrpDigIn
					case 2: // MemGrpDigOut
						return mem.val === 0 ? '#fff' : theme.palette.green.light;

					// MemGrpAnIn
					// MemGrpAnOut
					default:
						return '#fff';

				}
			} )() } }>
				<CardHeader
					title={ mem.name }
					titleTypographyProps={ { variant: 'body2' } }
					subheader={ ( () => {
						for( var term of terms ) {
							if( term.id === mem.termId ) {
								return term.name;
							}
						}

						return <i>nicht verbunden</i>;
					} )() }
					subheaderTypographyProps={ { variant: 'body2' } }
				/>
				<CardContent >
					<Typography align="center" variant="h6">
					{ ( () => {
						switch( mem.grp ) {

							case 1:
							case 2:
								return mem.val === 0 ? 'Aus' : 'Ein';

							case 3:
							case 4:
								return mem.val.toFixed( 1 ).replace( '.', ',' );

							default:
								return [ mem.val, ' (', mem.grp, ')' ];

						}
					} )() }
					</Typography>
					<Typography align="center" color="textSecondary" variant="body2">{ getFormattedDateTime( mem.valTsp ) }</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
};



export default connect( ( state, ownProps ) => ( {
	mem:	ownProps.mem,
	terms:	state.inv.terms
} ) )( PLCMember );