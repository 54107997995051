import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	withMobileDialog
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const TerminalDelete = ( {
	terms,
	deleteTerm,
	fullScreen, history
} ) => {
	const prms = useParams();
	const term = terms.find( term => term.id === +prms.termId );

	return(
		<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
			<DialogTitle>Klemme löschen</DialogTitle>
			<DialogContent>
				<Typography variant="body1" align="center">Soll die Klemme <b>{ term.name }</b> endgültig gelöscht werden?</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={ history.goBack }>abbrechen</Button>
				<Button color="secondary" onClick={ () => {
					deleteTerm( term.id );
					history.go( -2 );
				} }>löschen</Button>
			</DialogActions>
		</Dialog>
	);
};



 export default connect(
	state => ( {
		terms: state.inv.terms
	} ), dispatch => ( {
		deleteTerm: id => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 8, typ: 1, data: { id } } // MsgCmdTermCfgDel, MsgTypeRequest
		} )
	} )
 )( withRouter( withMobileDialog()( TerminalDelete ) ) );