import moment, { unix } from 'moment';



export const loginState = {
	NO:		1,
	PRGRS:	2,
	OK:		3,
	ERR:	4
};



export const getFormattedDateTime = tsp => {
	const date = unix( tsp );

	return date.isSame( moment(), 'day' ) ? date.format( 'HH:mm:ss' ) : date.format( 'DD.MM.YYYY' ) + ', ' + date.format( 'HH:mm:ss' );
};

export const getFormattedDuration = dur => {
	return ( dur < 0 ? '-' : '+' ) + moment.unix( Math.abs( dur ) ).utc().format( 'H:mm' );
};