import {
	DeviceDelete,
	DeviceEditor,
	DevicesList,
	LogItemsList,
	PLCMembersList,
	ScheduleItemDelete,
	ScheduleItemEditor,
	ScheduleTerminalsList,
	Settings,
	TerminalDelete,
	TerminalEditor,
	TerminalStatus,
	TerminalsList
} from './components';
import InfoBar from './infoBar';
import LoginDialog from './loginDialog';
import MainMenu from './mainMenu';
import { Container, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { loginState } from './utils';



const App = ( { cnnct, login } ) => (
	<Fragment>
	{ !cnnct &&
		<Typography variant="h6" align="center" color="error" style={ { margin: '10vH 0 0' } }>Keine Serververbindung</Typography>
	}
	{ cnnct && ( login === loginState.NO || login === loginState.ERR ) &&
		<LoginDialog err={ login === loginState.ERR }/>
	}
	{ login === loginState.PRGRS &&
		<Typography variant="h6" align="center" style={ { margin: '10vH 0 0' } }>Login wird ausgeführt</Typography>
	}
	{ login === loginState.OK &&
		<Container maxWidth="md">
			<MainMenu />
			<Route path={ [
				'/devs/:devId/terms',
				'/'
			] } children={ <InfoBar/> } />

			<Route path={ [
				'/devs/:devId/terms',
				'/devs/:devId/terms/add',
				'/devs/:devId/terms/:termId/delete',
				'/devs/:devId/terms/:termId/edit',
				'/devs/:devId/terms/:termId/status',
				'/favs/terms',
				'/favs/terms/:termId/delete',
				'/favs/terms/:termId/edit',
				'/favs/terms/:termId/status'
			] } exact children={ <TerminalsList /> } />
			<Route path={ [
				'/devs/:devId/terms/:termId/delete',
				'/favs/terms/:termId/delete'
			] } exact children={ <TerminalDelete /> } />
			<Route path={ [
				'/devs/:devId/terms/add',
				'/devs/:devId/terms/:termId/edit',
				'/favs/terms/:termId/edit'
			] } exact children={ <TerminalEditor /> } />
			<Route path={ [
				'/devs/:devId/terms/:termId/status',
				'/favs/terms/:termId/status'
			] } exact children={ <TerminalStatus /> } />

			<Route path={ [
				'/devs',
				'/devs/add',
				'/devs/:devId/delete',
				'/devs/:devId/edit'
			] } exact children={ <DevicesList /> } />
			<Route path={ [
				'/devs/:devId/delete'
			] } exact children={ <DeviceDelete /> } />
			<Route path={ [
				'/devs/add',
				'/devs/:devId/edit'
			] } exact children={ <DeviceEditor /> } />

			<Route path="/log" children={ <LogItemsList /> } />

			<Route path={ [
				'/sched'
			] } children={ <ScheduleTerminalsList /> } />
			<Route path={ [
				'/sched/:itmId/delete'
			] } children={ <ScheduleItemDelete /> } />
			<Route path={ [
				'/sched/add',
				'/sched/:itmId/edit'
			] } children={ <ScheduleItemEditor /> } />

			<Route path="/plc" children={ <PLCMembersList /> } />
			<Route path="/settings" children={ <Settings /> } />
		</Container>
	}
	</Fragment>
);



export default connect( state => ( {
	cnnct: state.sys.cnnct,
	login: state.sys.login
} ) )( App );