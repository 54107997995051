import { AppBar, Badge, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Add, Loop, Menu, Widgets } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const InfoBar = ( {
	devs, terms, logFlgs,
	getLogItms, openMenu,
	history
} ) => {
	const loc = useLocation();
	const prms = useParams();

	let err = false;

	for( let dev of devs ) {
		if( !!( dev.flgs & 0xf ) ) {
			err = true;
		}
	}

	for( let term of terms ) {
		if( !!( term.flgs & 0x2000 ) && !!( term.flgs & 0x5 ) ) {
			err = true;
		}
	}

	return(
		<Fragment>
			<AppBar color="inherit">
				<Toolbar>
					<IconButton children={ <Menu /> } color="primary" edge="start" onClick={ openMenu } />
					<div style={ { flexGrow: 1, margin: '0 0 0 8px' } }>
					{ ( () => {
						switch( true ) {

							case loc.pathname === '/devs':
								return <Typography color="primary" variant="h6">Geräte</Typography>

							case !!loc.pathname.match( /^\/devs\/\d+\/terms/ ):
								for( let dev of devs ) {
									if( dev.id === +prms.devId ) {
										return(
											<Fragment>
												<Typography variant="h6" color="primary" onClick={ history.goBack} style={ { cursor: 'pointer', textDecoration: 'none' } }>Geräte</Typography>
												<Typography variant="subtitle2" color="textSecondary">{ dev.name }</Typography>
											</Fragment>
										);
									}
								}

								return null;

							case !!loc.pathname.match( /^\/favs/ ):
								return <Typography color="primary" variant="h6">Favoriten</Typography>

							case loc.pathname === '/log':
								return <Typography color="primary" variant="h6">Ereignisse</Typography>

							case loc.pathname === '/plc':
								return <Typography color="primary" variant="h6">PLC</Typography>

							case !!loc.pathname.match( /^\/sched/ ):
								return <Typography color="primary" variant="h6">Zeitsteuerung</Typography>

							case loc.pathname === '/settings':
								return <Typography color="primary" variant="h6">Einstellungen</Typography>

						}

					} )() }
					</div>
				{ err &&
					<IconButton color="primary" edge="end" component={ Link } to="/devs">
						<Badge badgeContent="!" color="error" children={ <Widgets /> } />
					</IconButton>
				}
				{ loc.pathname === '/devs' &&
					<IconButton children={ <Add /> } color="primary" edge="end" component={ Link } to="/devs/add" />
				}
				{ !!loc.pathname.match( /^\/devs\/\d+\/terms$/ ) &&
					<IconButton children={ <Add /> } color="primary" edge="end" component={ Link } to={ `terms/add` } />
				}
				{ loc.pathname === '/log' &&
					<IconButton children={ <Loop /> } color="primary" edge="end" onClick={ () => getLogItms( logFlgs ) } />
				}
				{ !!loc.pathname.match( /^\/sched/ ) &&
					<IconButton children={ <Add /> } color="primary" edge="end" component={ Link } to="/sched/add" />
				}
				</Toolbar>
			</AppBar>
			<Toolbar />
		</Fragment>
	);
};



export default connect( state => ( {
	devs:		state.inv.devs,
	terms:		state.inv.terms,
	logFlgs:	state.log.flgs
} ), dispatch => ( {
	getLogItms:	flgs => dispatch( {
		type: 'WS_SEND',
		data: { uuid: uuidv4(), cmd:  31, typ: 1, data: { flgs } } // MsgCmdLogItmGet, MsgTypRequest
	} ),
	openMenu: () => dispatch( {
		type: 'GUI_MENU_OPEN',
		data: { menuOpen: true }
	} ),
} ) )( withRouter( InfoBar ) );