import { createHash } from 'crypto';
import { v4 as uuidv4 } from 'uuid';



const automator = ( { dispatch } ) => {
	let pw;
	let uuid;
	let ws;

	return next => action => {
		switch( action.type ) {

			case 'WS_INIT':
				ws = new WebSocket( `ws://${ window.location.hostname }/websites`, [ 'home_v1' ] );
				//ws = new WebSocket( `ws://paknsg.ddns.net/websites`, [ 'home_v1' ] );
				//ws = new WebSocket( `ws://stephan250878.no-ip.org/websites`, [ 'home_v1' ] );
				//ws = new WebSocket( `ws://10.40.27.255/websites`, [ 'home_v1' ] );
				ws.onopen = () => {
					dispatch( { type: 'WS_CONNECT' } );
					dispatch( { type: 'SYS_LOGIN' } );
				};
				ws.onmessage = msg => {
					const data = JSON.parse( msg.data );
					data.json = !!data.json ? JSON.parse( data.json ) : {};

					dispatch( { type: 'WS_RECEIVE', data } );
				};
				ws.onclose = () => {
					dispatch( { type: 'WS_DISCONNECT' } );
					window.setTimeout( dispatch, 1000, { type: 'WS_INIT' } );
				};

				break;

			case 'WS_RECEIVE':
				const msg = action.data;

				if( msg.uuid === uuid ) {
					switch( true ) {

						case msg.cmd === 18 && msg.typ === 2: // MsgCmdSysSalt && MsgTypResponse
							if( !localStorage.getItem( 'hash' ) ) {
								localStorage.setItem( 'hash', createHash( 'sha256' ).update( pw + msg.data.txt ).digest( 'hex' ) );
							}

							dispatch( {
								type: 'WS_SEND',
								data: {
									uuid: uuidv4(),
									cmd: 19, // MsgCmdSysLogin
									typ: 1, // MsgTypRequest
									data: {
										pw: createHash( 'sha256' ).update( localStorage.getItem( 'hash' ) + msg.data.num ).digest( 'hex' )
									}
								}
							} );

							break;

						case msg.cmd === 19 && msg.typ === 2: // MsgCmdSysLogin && MsgTypResponse
							if( !( msg.flgs & 0x1 ) ) {
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 5, typ: 1 } } ); // MsgCmdDevCfgGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 17, typ: 1 } } ); // MsgCmdDevStateGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 33, typ: 1 } } ); // MsgCmdPlcCfgGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 29, typ: 1 } } ); // MsgCmdSchedCfgGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 22, typ: 1 } } ); // MsgCmdSchedItmGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 26, typ: 1 } } ); // MsgCmdSysPshCfgGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 9, typ: 1 } } ); // MsgCmdTermCfgGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 14, typ: 1 } } ); // MsgCmdTermBattGet, MsgTypRequest
								dispatch( { type: 'WS_SEND', data: { uuid: uuidv4(), cmd: 16, typ: 1 } } ); // MsgCmdTermMeaGet, MsgTypRequest
							} else {
								localStorage.setItem( 'hash', '' );
							}

							break;

						case msg.cmd === 20 && msg.typ === 2: // MsgCmdSysLogout && MsgTypResponse
							localStorage.setItem( 'hash', '' );
							break;

					}
				}

				break;

			case 'WS_SEND':
				if( ws.readyState === WebSocket.OPEN ) {
					uuid = action.data.uuid;
					ws.send( JSON.stringify( action.data ) );
				}

				break;

			case 'SYS_LOGIN':
				switch( true ) {

					case !!action.data && action.data.pw !== undefined:
						pw = action.data.pw;

					case !!localStorage.getItem( 'hash' ):
						dispatch( {
							type: 'WS_SEND',
							data: { uuid: uuidv4(), cmd: 18, typ: 1 } // MsgCmgSysSalt, MsgTypRequest
						} );

				}

				break;

		}

		return next( action );
	};
};



export default automator;