import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Typography,
	useTheme,
	withMobileDialog
} from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const DeviceEditor = ( {
	devs,
	devsDiscAct, discDevs,
	addDev, discoverDevs, updateDev,
	fullScreen, history
} ) => {
	const loc = useLocation();
	const params = useParams();
	const theme = useTheme();

	const [ dev, setDev ] = useState( () => {
		switch( true ) {

			case loc.pathname === '/devs/add':
				return {};

			case !!loc.pathname.match( /^\/devs\/\d+\/edit$/ ):
				for( let dev of devs ) {
					if( dev.id === +params.devId ) {
						return dev;
					}
				}

				return {};

			default:
				return {};

		}
	} );

	return(
		<Dialog fullScreen={ fullScreen } fullWidth onClose={ history.goBack } open={ true }>
			<DialogTitle>{ !dev.id ? 'Gerät hinzufügen' : 'Gerät bearbeiten' }</DialogTitle>
			<DialogContent>
			{ !dev.id &&
				<FormControl fullWidth>
					<InputLabel id="devGrp">Gruppe</InputLabel>
					<Select labelId="devGrp" onChange={ evt => {
						switch( evt.target.value ) {

							case 1:
								setDev( { grp: 1, name: 'EasyMeter Q3A', uri: 'serial:///dev/ttyS1:9600:8N1', flgs: 0x0200 } );
								break;

							case 4:
								setDev( { grp: 4 } );
								discoverDevs();

								break;

							default:
								break;

						}
					} } value={ dev.grp || '' }>
						<MenuItem value={ 4 }>W&amp;T Web-IO</MenuItem>
						<MenuItem value={ 1 }>EasyMeter Q3A</MenuItem>
					</Select>
				</FormControl>
			}
			{ !dev.id && dev.grp === 4 &&
				<div style={ { alignItems: 'center', display: 'flex', margin: '24px 0 0' } }>
					<div style={ { flexGrow: '1', margin: '0 8px 0 0' } }>
						<FormControl fullWidth>
							<InputLabel id="devMac">Name</InputLabel>
							<Select labelId="devMac" onChange={ evt => setDev( evt.target.value ) } value={ !!dev.mac ? dev : '' }>
							{ discDevs.map( dev => <MenuItem color="secondary" key={ dev.mac } value={ dev }>{ dev.name }</MenuItem> ) }
							</Select>
							<FormHelperText>{ devsDiscAct ? 'Suche läuft...' : discDevs.length + ' Geräte gefunden' }</FormHelperText>
						</FormControl>
					</div>
					<Button color="primary" variant="outlined" size="small" disabled={ devsDiscAct } onClick={ () => {
						setDev( { grp: 4 } );
						discoverDevs();
					} }>Suchen</Button>
				</div>
			}
			{ dev.grp === 1 &&
				<Fragment>
					<TextField fullWidth label="Name" onChange={ evt => setDev( { ...dev, name: evt.target.value } ) } value={ dev.name } style={ { margin: '24px 0 0' } } />
					<FormControl fullWidth style={ { margin: '24px 0 0' } }>
						<InputLabel id="devUri">Port</InputLabel>
						<Select labelId="devUri" onChange={ evt => setDev( { ...dev, uri: evt.target.value } ) } value={ dev.uri }>
							<MenuItem value="serial:///dev/ttyS1:9600:8N1">/dev/ttyS1</MenuItem>
							<MenuItem value="serial:///dev/ttyUSB0:9600:8N1">/dev/ttyUSB0</MenuItem>
						</Select>
					</FormControl>
					<TableContainer style={ { margin: '24px 0 0' } }>
						<Table size="small">
							<TableBody>
							{ !!dev.id &&
								<TableRow>
									<TableCell variant="head" align="right">Gruppe</TableCell>
									<TableCell>EasyMeter Q3A</TableCell>
								</TableRow>
							}
								<TableRow>
									<TableCell variant="head" align="right" width="50%" style={ { border: 'none' } }>serielle Konfiguration</TableCell>
									<TableCell style={ { border: 'none' } }>9600, 8N1</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Fragment>
			}
			{ dev.grp === 4 && !!dev.mac &&
				<Fragment>
					<TableContainer style={ { margin: '24px 0 0' } }>
						<Table size="small">
							<TableBody>
							{ !!dev.id &&
								<TableRow>
									<TableCell variant="head" align="right">Gruppe</TableCell>
									<TableCell>W&amp;T Web-IO</TableCell>
								</TableRow>
							}
								<TableRow>
									<TableCell variant="head" align="right" width="50%">MAC-Adresse</TableCell>
									<TableCell>{ dev.mac }</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head" align="right">URI</TableCell>
									<TableCell>{ dev.uri }</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head" align="right">Firmware</TableCell>
									<TableCell>{ dev.ver }</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head" align="right" style={ { border: 'none' } }>Status</TableCell>
									<TableCell style={ { border: 'none' } }>
									{ ( () => {
										switch( true ) {

											case ( dev.flgs & 0xff ) === 0:
												return <Typography variant="inherit" style={ { color: theme.palette.green.main } }>erreichbar</Typography>;

											case ( dev.flgs & 0x04 ) !== 0:
												return <Typography variant="inherit" style={ { color: theme.palette.red.main } }>nicht erreichbar</Typography>;

											default:
												return <Typography variant="inherit" style={ { color: theme.palette.red.main } }>unbekannt</Typography>;

										}
									} )() }
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<TextField fullWidth label="Passwort" value={ dev.pw } onChange={ evt => setDev( { ...dev, pw: evt.target.value } ) } style={ { margin: '24px 0 0' } } />
				</Fragment>
			}
			</DialogContent>
			<DialogActions>
			{ !!dev.id &&
				<div style={ { flexGrow: '1', margin: '0 0 0 8px' } }>
					<Button color="secondary" onClick={ () => history.push( 'delete' ) }>löschen</Button>
				</div>
			}
				<div style={ { margin: '0 8px 0 0' } }>
					<Button color="primary" onClick={ history.goBack }>verwerfen</Button>
					<Button color="primary" disabled={ ( () => {
						switch( dev.grp ) {

							case 1:
								return !dev.uri;

							case 4:
								return !dev.mac;

							default:
								return true;

						}
					} )() } onClick={ () => {
						!dev.id ? addDev( dev ) : updateDev( dev );
						history.goBack();
					} }>speichern</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}



export default connect(
	state => ( {
		devs:			state.inv.devs,
		devsDiscAct:	state.gui.devsDiscAct,
		discDevs:		state.gui.discDevs
	} ), dispatch => ( {
		addDev: dev => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 1, typ: 1, data: dev } // MsgCmdDevCfgAdd, MsgTypeRequest
		} ),
		discoverDevs:	() => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 4, typ: 1, data: { grp: 4 } } // MsgCmdDevDisc, MsgTypeRequest
		} ),
		updateDev: dev => dispatch( {
			type: 'WS_SEND',
			data: { uuid: uuidv4(), cmd: 6, typ: 1, data: dev } // MsgCmdDevCfgSet, MsgTypeRequest
		} )
	} )
)( withRouter( withMobileDialog()( DeviceEditor ) ) );